import { CardStyles } from 'Components/Common/Card';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useParams } from 'react-router-dom';
import { makeClusterObjectRef } from 'utils/helpers';
import { getPolicyTypeFromId } from 'utils/types';
import { AccessDetails } from './AccessDetails';
import { AccessLogDetails } from './AccessLogDetails';
import { ActiveHealthCheckDetails } from './ActiveHealthCheckDetails';
import { AdaptiveRequestConcurrencyDetails } from './AdaptiveRequestConcurrencyDetails';
import { ClientTlsDetails } from './ClientTlsDetails';
import { ConnectionDetails } from './ConnectionDetails';
import { CorsDetails } from './CorsDetails';
import { CsrfDetails } from './CsrfDetails';
import { DlpDetails } from './DlpDetails';
import { ExtAuthDetails } from './ExtAuthDetails';
import { FailoverDetails } from './FailoverDetails';
import { FaultInjectionDetails } from './FaultInjectionDetails';
import { GraphqlAllowedQueryDetails } from './GraphqlAllowedQueryDetails';
import { GraphqlPersistedQueryCacheDetails } from './GraphqlPersistedQueryCacheDetails';
import { HeaderManipulationDetails } from './HeaderManipulationDetails';
import { HttpBufferPolicyDetails } from './HttpBufferPolicyDetails';
import { JwtDetails } from './JwtDetails';
import { ListenerConnectionDetails } from './ListenerConnectionDetails';
import { LoadBalancerDetails } from './LoadBalancerDetails';
import { MirrorDetails } from './MirrorDetails';
import { OutlierDetectionDetails } from './OutlierDetectionDetails';
import { ProxyProtocolDetails } from './ProxyProtocolDetails';
import { RateLimitDetails } from './RateLimitDetails';
import { RetryDetails } from './RetryDetails';
import { TransformationDetails } from './TransformationDetails';
import { TrimProxyConfigDetails } from './TrimProxyConfigDetails';
import { WafDetails } from './WafDetails';
import { WasmDeploymentDetails } from './WasmDeploymentDetails';

const detailsMap: Record<PolicyType, typeof AccessDetails | undefined> = {
  [PolicyType.UNKNOWN_POLICY_TYPE]: undefined,
  [PolicyType.RETRY_TIMEOUT]: RetryDetails,
  [PolicyType.FAULT_INJECTION]: FaultInjectionDetails,
  [PolicyType.ACCESS]: AccessDetails,
  [PolicyType.OUTLIER_DETECTION]: OutlierDetectionDetails,
  [PolicyType.FAILOVER]: FailoverDetails,
  [PolicyType.MIRROR]: MirrorDetails,
  [PolicyType.CORS]: CorsDetails,
  [PolicyType.CSRF]: CsrfDetails,
  [PolicyType.EXT_AUTH]: ExtAuthDetails,
  [PolicyType.RATE_LIMIT]: RateLimitDetails,
  [PolicyType.HEADER_MANIPULATION]: HeaderManipulationDetails,
  [PolicyType.ACCESS_LOG]: AccessLogDetails,
  [PolicyType.WAF]: WafDetails,
  [PolicyType.JWT]: JwtDetails,
  [PolicyType.WASM_DEPLOYMENT]: WasmDeploymentDetails,
  [PolicyType.CONNECTION]: ConnectionDetails,
  [PolicyType.TRANSFORMATION]: TransformationDetails,
  [PolicyType.PROXY_PROTOCOL]: ProxyProtocolDetails,
  [PolicyType.HTTP_BUFFER]: HttpBufferPolicyDetails,
  [PolicyType.CLIENT_TLS]: ClientTlsDetails,
  [PolicyType.DLP]: DlpDetails,
  [PolicyType.ACTIVE_HEALTH_CHECK]: ActiveHealthCheckDetails,
  [PolicyType.TRIM_PROXY_CONFIG]: TrimProxyConfigDetails,
  [PolicyType.LOAD_BALANCER]: LoadBalancerDetails,
  [PolicyType.LISTENER_CONNECTION]: ListenerConnectionDetails,
  [PolicyType.GRAPHQL_ALLOWED_QUERY_POLICY]: GraphqlAllowedQueryDetails,
  [PolicyType.GRAPHQL_PERSISTED_QUERY_CACHE]: GraphqlPersistedQueryCacheDetails,
  [PolicyType.ADAPTIVE_REQUEST_CONCURRENCY_POLICY]: AdaptiveRequestConcurrencyDetails
};

export function isPolicyTypeDetailsPageSupported(policyType: PolicyType) {
  return !!detailsMap[policyType];
}

export function PolicyDetailsLanding() {
  const { policyType: policyTypeId, policyName, policyNamespace, policyCluster } = useParams();
  const clusterObjRef = makeClusterObjectRef(policyName, policyNamespace, policyCluster);

  if (!clusterObjRef) {
    return (
      <CardStyles.Card>
        <CardStyles.CardHeader>Unknown Policy Reference</CardStyles.CardHeader>
        Some or all of the provided cluster reference is unknown:
        {[policyName, policyNamespace, policyCluster].map(s => s ?? 'unknown').join('.')}
      </CardStyles.Card>
    );
  }

  const policyType = getPolicyTypeFromId(policyTypeId);
  const DetailsComponent = detailsMap[policyType!];
  if (DetailsComponent) {
    return <DetailsComponent clusterObjRef={clusterObjRef} />;
  }
  return (
    <CardStyles.Card>
      <CardStyles.CardHeader>Unknown Policy Type</CardStyles.CardHeader>
      Policy type '{policyTypeId ?? 'unknown'}' is not currently supported by Gloo Platform UI.
    </CardStyles.Card>
  );
}
