import { workspacesApi } from 'Api/workspaces';
import { ApiserverNoContent } from 'Components/Common/ApiserverContent';
import { CardStyles } from 'Components/Common/Card';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import { SoloMultiselect } from 'Components/Common/Input/SoloMultiselect';
import { Loading } from 'Components/Common/Loading';
import { useSoloPaging } from 'Components/Common/SoloPagination';
import { PolicyType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { FlexLayoutSpacer } from 'Styles/CommonEmotions/flexLayout';
import { getEnumValues } from 'utils/helpers';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { policyTypeMap } from 'utils/types';
import { WorkspaceTabStyles as Styles } from '../WorkspaceTabUtils.style';
import { WorkspacePoliciesTable } from './WorkspacePoliciesTable';

const { useListPolicies } = workspacesApi;

const policyTypeOptions = getEnumValues(PolicyType)
  .filter(key => key !== PolicyType.UNKNOWN_POLICY_TYPE)
  .map(policyType => {
    const usefulPolicyTypeInfo = policyTypeMap[policyType];

    return {
      value: policyType,
      title: usefulPolicyTypeInfo.name,
      icon: usefulPolicyTypeInfo.icon
    };
  })
  .sort((a, b) => a.title.localeCompare(b.title));

export const PoliciesTab = ({ workspaceName }: { workspaceName: string }) => {
  di(useListPolicies);
  const { pagingData, apiPaginationObject, setPagingTotal } = useSoloPaging();

  const [workspaceNameFilter, setWorkspaceNameFilter] = useState<string>('');
  const [workspaceTypeFilter, setWorkspaceTypeFilter] = useState<PolicyType[]>([]);

  const { data: policiesData, error: policiesError } = useListPolicies(
    workspaceName,
    workspaceNameFilter,
    workspaceTypeFilter,
    apiPaginationObject
  );

  const { initialLoad, showRefreshIndicator } = useDebouncedRefreshIndicator(policiesData);

  useEffect(() => {
    setPagingTotal(policiesData?.totalWorkspacePolicies);
  }, [policiesData?.totalWorkspacePolicies]);

  return (
    <CardStyles.Card data-testid='policies-tab'>
      <CardStyles.CardHeader>
        <FlexLayoutSpacer>Workspace Policies</FlexLayoutSpacer>
        <Styles.CardHeaderRightTools>
          <div>{showRefreshIndicator ? <Loading small center /> : null}</div>
          <SoloInput
            value={workspaceNameFilter}
            placeholder={'Search by name...'}
            onChangeValue={setWorkspaceNameFilter}
          />
          <SoloMultiselect
            values={workspaceTypeFilter}
            options={policyTypeOptions}
            placeholder={'Filter by Type'}
            onChange={setWorkspaceTypeFilter}
            multiple
          />
        </Styles.CardHeaderRightTools>
      </CardStyles.CardHeader>
      {!!policiesError || initialLoad ? (
        <ApiserverNoContent error={policiesError} loading={`Retrieving ${workspaceName} policy details...`} />
      ) : (
        <WorkspacePoliciesTable
          workspaceName={workspaceName}
          workspacePolicies={policiesData?.workspacePolicies ?? []}
          pagingData={pagingData}
        />
      )}
    </CardStyles.Card>
  );
};
