import { RpcError } from '@protobuf-ts/runtime-rpc';
import { dashboardApi } from 'Api/dashboard';
import { loginApi, TempRoles } from 'Api/login';
import {
  GetUIFeatureFlagsResponse,
  GetUIFeatureFlagsResponse_License,
  GetUIFeatureFlagsResponse_License_LicenseState
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/dashboard_pb';
import { useCallback, useEffect, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useNavigate } from 'react-router-dom';
import { NoticeUrgencyLevel, useNotifications } from './notificationsystem';

const { useGetLoggedInRole } = loginApi;
const { useGetFeatureFlags } = dashboardApi;

type UserViewableLicenseType = 'gateway' | 'mesh' | 'network' | 'core';
type NonviewableLicenseType = 'any';
type AllLicensesType = UserViewableLicenseType | NonviewableLicenseType;
type LicenseData = GetUIFeatureFlagsResponse_License;

export enum Permission {
  CanViewClustersArea,
  CanViewFlags,
  CanViewApisPage,
  ViewPolicyGlooPlatform,
  ViewIngress,
  ViewEgress,

  // General License Check - should only be used when things are explicitly tied to license, such as sidebar
  PlatformLicense,
  CoreLicense,
  NetworkForCiliumLicense,

  // Main Feature Permissions
  IstioEnabled,
  CiliumEnabled,
  Kubernetes,
  GatewayApi,
  SoloResources,

  // Feature Permissions - Api Server Flags
  PortalEnabled,
  GraphEnabled,
  InsightsEnabled,

  // Feature Permissions
  GraphQLEnabled,
  DebugEnabled,
  LogsEnabled,
  TcpEnabled,
  Workspace
}

const rolePermissions: Record<TempRoles, Permission[]> = {
  [TempRoles.WorkspaceUser]: [],
  [TempRoles.WorkspaceAdmin]: [],
  [TempRoles.SuperUser]: [Permission.CanViewClustersArea]
};

export function checkLicenseEnabled(license: LicenseData | undefined): boolean {
  return (
    license?.state === GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_GRANTED ||
    license?.state === GetUIFeatureFlagsResponse_License_LicenseState.ACCESS_EXPIRES_SOON
  );
}

export function useLicenses() {
  di(useGetFeatureFlags);
  const { data: featureFlags, error: featureFlagsError } = useGetFeatureFlags();
  const loading = (featureFlags ?? featureFlagsError) === undefined;
  const errors: Partial<RpcError>[] = [featureFlagsError].filter(e => !!e);

  const {
    glooGateway: gatewayLicense,
    glooMesh: meshLicense,
    glooNetwork: networkLicense,
    glooCore: coreLicense,
    any: anyLicense
  } = featureFlags?.licenses ?? {};

  // These are the licenses viewable by the user in the top-right dropdown on the site.
  //  We prompt them with that dropdown system to maintain their licensures.
  //  Updates here may need to be reflected there.
  const userViewableLicenses = useMemo<Record<UserViewableLicenseType, LicenseData | undefined>>(() => {
    return {
      gateway: gatewayLicense,
      mesh: meshLicense,
      network: networkLicense,
      core: coreLicense
    };
  }, [gatewayLicense, meshLicense, networkLicense, coreLicense]);

  const licenses = useMemo<Record<AllLicensesType, LicenseData | undefined>>(() => {
    return {
      ...userViewableLicenses,
      any: anyLicense
    };
  }, [userViewableLicenses, anyLicense]);

  const licenseEnabledMap = useMemo(
    () =>
      Object.fromEntries(Object.entries(licenses).map(([k, v]) => [k, checkLicenseEnabled(v)])) as Record<
        AllLicensesType,
        boolean
      >,
    [licenses]
  );

  return {
    userViewableLicenses,
    licenses,
    licenseEnabledMap,
    loading,
    errors
  };
}

export function usePermissions() {
  di(useGetLoggedInRole, useGetFeatureFlags);

  const { data: rolePrivileges, error: roleError } = useGetLoggedInRole();
  const { licenseEnabledMap, loading: licensesLoading, errors: licensesErrors } = useLicenses();
  const { data: featureFlags, error: featureFlagsError } = useGetFeatureFlags();
  // Check if all of the above have finished loading
  const loading = (rolePrivileges ?? roleError ?? featureFlags) === undefined || licensesLoading;
  const errors: Partial<RpcError>[] = [roleError, ...licensesErrors, featureFlagsError].filter(e => !!e);

  const hasRolePermission = useCallback(
    (perm: Permission) => {
      if (rolePrivileges?.role === undefined || rolePermissions[rolePrivileges.role] === undefined) {
        if (!loading) {
          // eslint-disable-next-line no-console
          console.log('Unknown or unsupported role:', rolePrivileges?.role ?? 'undefined');
        }
        return false;
      } else {
        return rolePermissions[rolePrivileges.role].includes(perm);
      }
    },
    [rolePrivileges?.role]
  );

  const enabledFlags: Required<Omit<GetUIFeatureFlagsResponse, 'licenses'>> = {
    portalEnabled: licenseEnabledMap.gateway,
    graphEnabled: featureFlags?.graphEnabled ?? false,
    inightsEnabled: !!featureFlags?.inightsEnabled,
  };

  // Permissions not tied to roles, but to specific flags the user has
  const userPermissions: Partial<Record<Permission, boolean>> = {
    [Permission.CanViewFlags]: process.env.NODE_ENV === 'development',
    // Apis page can be accessed if either graphql or portal is accessible to the user
    [Permission.CanViewApisPage]: licenseEnabledMap.gateway,
    [Permission.ViewPolicyGlooPlatform]: licenseEnabledMap.gateway || licenseEnabledMap.mesh,
    [Permission.ViewIngress]: true,
    [Permission.ViewEgress]: false, // Currently disabled, as per https://github.com/solo-io/gloo-mesh-enterprise/issues/18153

    [Permission.PlatformLicense]: licenseEnabledMap.gateway || licenseEnabledMap.mesh,
    [Permission.CoreLicense]: enabledFlags.inightsEnabled,
    [Permission.NetworkForCiliumLicense]: licenseEnabledMap.network,

    [Permission.IstioEnabled]: licenseEnabledMap.gateway || licenseEnabledMap.mesh || licenseEnabledMap.core,
    [Permission.CiliumEnabled]: licenseEnabledMap.network || licenseEnabledMap.core,
    [Permission.Kubernetes]: true,
    [Permission.GatewayApi]: true,
    [Permission.SoloResources]: true,

    [Permission.PortalEnabled]: enabledFlags.portalEnabled,
    [Permission.GraphEnabled]: enabledFlags.graphEnabled,
    [Permission.InsightsEnabled]: enabledFlags.inightsEnabled,

    [Permission.GraphQLEnabled]: licenseEnabledMap.gateway,
    [Permission.DebugEnabled]: licenseEnabledMap.any,
    [Permission.LogsEnabled]: licenseEnabledMap.mesh || licenseEnabledMap.network || licenseEnabledMap.core,
    [Permission.TcpEnabled]: true,
    [Permission.Workspace]:
      licenseEnabledMap.any || licenseEnabledMap.gateway || licenseEnabledMap.mesh || licenseEnabledMap.network
  };

  return {
    hasPerm(perm: Permission) {
      return hasRolePermission(perm) || (userPermissions[perm] ?? false);
    },
    loading,
    errors
  };
}

/**
 * Prevents access to a page to a user without requires permissions by redirecting user to a different page.
 */
export function usePermissionGateRedirect(perm: Permission | undefined, redirectLocation = '/') {
  const { notifyUser } = useNotifications();
  const { hasPerm, loading: permissionsLoading } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!permissionsLoading && perm !== undefined && !hasPerm(perm)) {
      navigate(redirectLocation, { replace: true });
      notifyUser({
        id: `${redirectLocation}-${perm}`,
        level: NoticeUrgencyLevel.Info,
        message: 'You have been redirected because you do not have permission to view the visited page.',
        dismissable: true
      });
    }
  }, [permissionsLoading, perm, redirectLocation]);
}
