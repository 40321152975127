/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/trafficcontrol/load_balancer_policy.proto" (package "trafficcontrol.policy.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// Decide how to load balance incoming requests to an upstream service and enforce sticky sessions.
// The LoadBalancerPolicy provides the following settings to configure traffic distribution among destination workloads.
//
// </br>**Simple load balancing**: By default, the gateway forwards incoming requests to the instance
// with the least requests. You can change this behavior by choosing one of multiple load
// balancing algorithms for selecting upstream services to forward incoming requests to.
// To configure simple load balancing for incoming requests, you use the `spec.config.
// simple` setting in the load balancer policy.
// 
// Example: The following load balancer policy randomly selects one of the available reviews services to serve an incoming request. 
// ```yaml
// apiVersion: trafficcontrol.policy.gloo.solo.io/v2
// kind: LoadBalancerPolicy
// metadata:
//   name: simple-loadbalancer-policy
//   namespace: bookinfo
// spec:
//   applyToDestinations:
//   - port:
//       number: 9080
//     selector:
//       labels:
//         app: reviews
//   config:
//     simple: RANDOM
// ```
// 
// **Session affinity and consistent hashing**: The load balancer policy 
// allows you to set up soft session affinity between a client and an upstream service by 
// using a consistent hashing algorithm based on HTTP headers, cookies,
// the source IP address, or a query parameter.
// To configure consistent hashing, you use the `spec.config.consistentHash` setting
// in the load balancer policy.
// 
// Example: The following example sets up session affinity between a client and an upstream service by using a cookie.
// ```yaml
// apiVersion: trafficcontrol.policy.gloo.solo.io/v2
// kind: LoadBalancerPolicy
// metadata:
//   name: sticky-loadbalancer-policy
//   namespace: bookinfo
// spec:
//   applyToDestinations:
//   - port:
//       number: 9080
//     selector:
//       labels:
//         app: reviews
//   config:
//     consistentHash:
//       httpCookie:
//         name: chocolate-chip
//         ttl: 10s
// ```
// 
// **Healthy panic threshold**: By default, the gateway only considers services that are healthy 
// and available when load balancing incoming requests among upstream services. In the 
// case that the number of healthy upstream services becomes too low, you can instruct 
// the gateway to disregard the upstream health status and either load balance requests 
// among all or no hosts by using the `healthyPanicThreshold` setting.
// 
// Example: The following example disables the healthy panic threshold for the reviews upstream service.
// ```yaml
// apiVersion: trafficcontrol.policy.gloo.solo.io/v2
// kind: LoadBalancerPolicy
// metadata:
//   name: simple-loadbalancer-policy
//   namespace: bookinfo
// spec:
//   applyToDestinations:
//   - port:
//       number: 9080
//     selector:
//       labels:
//         app: reviews
//   config:
//     healthyPanicThreshold: 0
//     simple: ROUND_ROBIN
// ```
// 
// **Update merge window**: Sometimes, your deployments might have health checks and 
// metadata updates that use a lot of CPU and memory. In such cases, you can use the
// `updateMergeWindow` setting. This way, the gateway merges all updates together within a 
// specific timeframe.
// 
// Example: The following example collects and merges all health check updates during a 50s timeframe.
// ```yaml
// apiVersion: trafficcontrol.policy.gloo.solo.io/v2
// kind: LoadBalancerPolicy
// metadata:
//   name: simple-loadbalancer-policy
//   namespace: bookinfo
// spec:
//   applyToDestinations:
//   - port:
//       number: 9080
//     selector:
//       labels:
//         app: reviews
//   config:
//     updateMergeWindow: 50s
//     simple: ROUND_ROBIN
// ```
//
// **Warm up duration**: If you have new upstream services that need time to get ready for
// traffic, use the `warmupDurationSecs` setting. This way, the gateway gradually increases the 
// amount of traffic for the service. This setting is effective in scaling events, such as 
// when new replicas are added to handle increased load. However, if all services start at 
// the same time, this setting might not be as effective as all endpoints receiving the same amount of requests.
//
// **Example**: The following example configures the gateway to wait 10 seconds before requests are routed to newly created review instances. 
// ```yaml
// apiVersion: trafficcontrol.policy.gloo.solo.io/v2
// kind: LoadBalancerPolicy
// metadata:
//   name: simple-loadbalancer-policy
//   namespace: bookinfo
// spec:
//   applyToDestinations:
//   - port:
//       number: 9080
//     selector:
//       labels:
//         app: reviews
//   config:
//     warmupDurationSecs: 10s
//     simple: ROUND_ROBIN
// ```
//
import { MessageType } from "@protobuf-ts/runtime";
import { DestinationReference } from "../../../common/v2/references_pb";
import { Report } from "../../../common/v2/status_pb";
import { Status } from "../../../common/v2/status_pb";
import { DoubleValue } from "../../../../../../../../../google/protobuf/wrappers_pb";
import { Duration } from "../../../../../../../../../google/protobuf/duration_pb";
import { DestinationSelector } from "../../../common/v2/selectors_pb";
/**
 * Specifications for the policy.
 *
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec
 */
export interface LoadBalancerPolicySpec {
    /**
     * Destinations to apply the policy to.
     * If empty or unset, the policy applies to all destinations in the workspace.
     *
     * </br>**Configuration constraints**: Only one load balancer policy can apply to a destination.
     * Subsequent policies (sorted by creation time) are ignored and put into a FAILED state.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationSelector apply_to_destinations = 1;
     */
    applyToDestinations: DestinationSelector[];
    /**
     * The configuration for load balancer settings.
     *
     * +kubebuilder:validation:Required
     * +kubebuilder:validation:XValidation:rule="has(self.simple) || has(self.consistentHash)",message="One of config.simple or config.consistentHash must be set."
     *
     * @generated from protobuf field: trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config config = 2;
     */
    config?: LoadBalancerPolicySpec_Config;
}
/**
 * The configuration for load balancer settings.
 *
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config
 */
export interface LoadBalancerPolicySpec_Config {
    /**
     * @generated from protobuf oneof: lb_policy
     */
    lbPolicy: {
        oneofKind: "simple";
        /**
         * Set a load balancing algorithm for selecting upstream services to forward incoming
         * requests to.
         *
         * +kubebuilder:validation:Enum=UNSPECIFIED;RANDOM;PASSTHROUGH;ROUND_ROBIN;LEAST_REQUEST
         *
         * @generated from protobuf field: trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.SimpleLB simple = 1;
         */
        simple: LoadBalancerPolicySpec_Config_SimpleLB;
    } | {
        oneofKind: "consistentHash";
        /**
         * Set up soft session affinity between a client and an upstream service by
         * using a consistent hashing algorithm based on HTTP headers, cookies, or other
         * properties.
         *
         * @generated from protobuf field: trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.ConsistentHashLB consistent_hash = 2;
         */
        consistentHash: LoadBalancerPolicySpec_Config_ConsistentHashLB;
    } | {
        oneofKind: undefined;
    };
    /**
     * The warm-up duration for a service. If set, the newly created endpoint of the
     * service remains in warm-up mode, starting from its creation time and for the
     * duration of this window. The gateway progressively increases the amount of traffic for that
     * endpoint instead of sending a proportional amount of traffic.
     * This setting is effective in scaling events, such as when new replicas are added to
     * handle increased load. However, if all services start at the same time, this setting
     * might not be as effective as all endpoints receiving the same amount of requests.
     *
     * </br>**Implementation notes**: This setting is supported only when `config.simple` is set to ROUND_ROBIN (default) or LEAST_REQUEST.
     *
     * </br>**Configuration constraints**:<ul>
     * <li>The value must be at least 1ms.</li>
     * <li>The value cannot have granularity smaller than one millisecond.</li></ul>
     *
     * +kubebuilder:validation:XValidation:rule="duration(self) >= duration('1ms')",message="The value must be at least 1ms."
     * +kubebuilder:validation:XValidation:rule="!self.contains('ns') && !self.contains('us') && !self.contains('μs')",message="The value cannot have granularity smaller than milliseconds."
     * +kubebuilder:validation:XValidation:rule="(duration(self)-duration('1ns')).getMilliseconds() == duration(self).getMilliseconds()-1",message="The value cannot have granularity smaller than one millisecond."
     *
     * @generated from protobuf field: google.protobuf.Duration warmup_duration_secs = 3;
     */
    warmupDurationSecs?: Duration;
    /**
     * The threshold at which Envoy disregards the upstream health status and either
     * load balances requests either among all or no hosts.
     *
     * </br>**Implementation notes**:<ul>
     * <li>Defaults to 50% (entered as `50`).</li>
     * <li>To disable panic mode, set to 0.</li></ul>
     *
     * </br>**Configuration constraints**: The value must be in the range 0 - 100, inclusive.
     *
     * +kubebuilder:validation:Minimum=0
     * +kubebuilder:validation:Maximum=100
     * +kubebuilder:validation:XValidation:rule="self == 0.0 || self >= 0.0001",message="The value must be >= 0.0001 or equal to zero."
     *
     * @generated from protobuf field: google.protobuf.DoubleValue healthy_panic_threshold = 4;
     */
    healthyPanicThreshold?: DoubleValue;
    /**
     * The duration of time within which the gateway merges all health check, weight, and
     * metadata updates together.
     *
     * </br>**Implementation notes**:<ul>
     * <li>Defaults to 1s.</li>
     * <li>To disable this setting, set to 0.</li></ul>
     *
     * </br>**Configuration constraints**:<ul>
     * <li>The value must be at least 1ms.</li>
     * <li>The value cannot have granularity smaller than one millisecond.</li></ul>
     *
     * +kubebuilder:validation:XValidation:rule="duration(self) >= duration('1ms')",message="The value must be at least 1ms."
     * +kubebuilder:validation:XValidation:rule="!self.contains('ns') && !self.contains('us') && !self.contains('μs')",message="The value cannot have granularity smaller than milliseconds."
     * +kubebuilder:validation:XValidation:rule="(duration(self)-duration('1ns')).getMilliseconds() == duration(self).getMilliseconds()-1",message="The value cannot have granularity smaller than one millisecond."
     *
     * @generated from protobuf field: google.protobuf.Duration update_merge_window = 5;
     */
    updateMergeWindow?: Duration;
}
/**
 * Provide soft session affinity based on HTTP headers, cookies,
 * the source IP address, or a query parameter.
 * The affinity to a particular destination host might be
 * lost when one or more hosts are added or removed from the destination
 * service.
 *
 * {{% alert context="info" %}}
 * Consistent hashing is less reliable than a common sticky session implementation,
 * in which the upstream service is encoded in a cookie and affinity can be maintained
 * for as long as the upstream service is available. With consistent hashing, affinity
 * might be lost when an upstream service is added or removed.
 * {{% /alert %}}
 *
 * {{% alert context="warning" %}}
 * Consistent hashing requires all proxies to be aware of the same endpoints.
 * This requirement is not met if you configure locality-based load balancing
 * and proxies are spread across localities.
 * To use locality-based load balancing and consistent hashing together,
 * all proxies must be in the same locality,
 * or a high-level load balancer must handle locality affinity.
 * {{% /alert %}}
 *
 * **Configuration constraints**: Only one of the following settings can be set.
 *
 * +kubebuilder:validation:XValidation:rule="has(self.httpHeaderName) || has(self.httpCookie) || has(self.useSourceIp) || has(self.httpQueryParameterName)",message="One of httpHeaderName, httpCookie, useSourceIp, or httpQueryParameterName must be set."
 *
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.ConsistentHashLB
 */
export interface LoadBalancerPolicySpec_Config_ConsistentHashLB {
    /**
     * @generated from protobuf oneof: hash_key
     */
    hashKey: {
        oneofKind: "httpHeaderName";
        /**
         * Hash based on a specific HTTP header.
         *
         * +kubebuilder:validation:MinLength=1
         *
         * @generated from protobuf field: string http_header_name = 1;
         */
        httpHeaderName: string;
    } | {
        oneofKind: "httpCookie";
        /**
         * Hash based on an HTTP cookie.
         *
         * @generated from protobuf field: trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.ConsistentHashLB.HTTPCookie http_cookie = 2;
         */
        httpCookie: LoadBalancerPolicySpec_Config_ConsistentHashLB_HTTPCookie;
    } | {
        oneofKind: "useSourceIp";
        /**
         * Hash based on the source IP address.
         * This is applicable for both TCP and HTTP connections.
         *
         * @generated from protobuf field: bool use_source_ip = 3;
         */
        useSourceIp: boolean;
    } | {
        oneofKind: "httpQueryParameterName";
        /**
         * Hash based on a specific HTTP query parameter.
         *
         * +kubebuilder:validation:MinLength=1
         *
         * @generated from protobuf field: string http_query_parameter_name = 4;
         */
        httpQueryParameterName: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * An HTTP cookie that is used as the hash key for the
 * consistent hash load balancer. If the cookie is not present, it is generated.
 *
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.ConsistentHashLB.HTTPCookie
 */
export interface LoadBalancerPolicySpec_Config_ConsistentHashLB_HTTPCookie {
    /**
     * Name of the cookie.
     *
     * +kubebuilder:validation:Required
     * +kubebuilder:validation:MinLength=1
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Path to set for the cookie.
     *
     * @generated from protobuf field: string path = 2;
     */
    path: string;
    /**
     * Lifetime of the cookie.
     *
     * **Configuration constraints**: The value cannot have granularity smaller than one millisecond.
     *
     * +kubebuilder:validation:XValidation:rule="duration(self).getMilliseconds() >= 0",message="The value must be a valid non-negative duration."
     * +kubebuilder:validation:XValidation:rule="!self.contains('ns') && !self.contains('us') && !self.contains('μs')",message="The value cannot have granularity smaller than milliseconds."
     * +kubebuilder:validation:XValidation:rule="!self.matches('[1-9]') || (duration(self)-duration('1ns')).getMilliseconds() == duration(self).getMilliseconds()-1",message="The value cannot have granularity smaller than one millisecond."
     *
     * @generated from protobuf field: google.protobuf.Duration ttl = 3;
     */
    ttl?: Duration;
}
/**
 * The load balancing algorithm for selecting upstream services to forward incoming
 * requests to.
 *
 * @generated from protobuf enum trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.SimpleLB
 */
export enum LoadBalancerPolicySpec_Config_SimpleLB {
    /**
     * No load balancing algorithm is specified.
     * Istio selects an appropriate algorithm.
     *
     * @generated from protobuf enum value: UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * The load balancer selects a random healthy host. The random
     * load balancer generally performs better than round robin if no health
     * checking policy is configured.
     *
     * @generated from protobuf enum value: RANDOM = 1;
     */
    RANDOM = 1,
    /**
     * Forward the connection to the original IP address
     * requested by the caller without doing any form of load
     * balancing. Use caution with this setting, which is intended only
     * for advanced use cases.
     *
     * @generated from protobuf enum value: PASSTHROUGH = 2;
     */
    PASSTHROUGH = 2,
    /**
     * The load balancer performs basic round robin load balancing.
     * This algorithm is generally unsafe for many scenarios,
     * such as when endpoint weighting is used, as it can
     * overburden endpoints. In general, LEAST_REQUEST is recommended
     * as a drop-in replacement for ROUND_ROBIN.
     *
     * @generated from protobuf enum value: ROUND_ROBIN = 3;
     */
    ROUND_ROBIN = 3,
    /**
     * The request load balancer spreads load across endpoints, favoring
     * endpoints with the least outstanding requests. This is generally safer
     * and performs better than ROUND_ROBIN in nearly all cases.
     *
     * @generated from protobuf enum value: LEAST_REQUEST = 4;
     */
    LEAST_REQUEST = 4
}
/**
 * The status of the policy after it is applied to your Gloo environment.
 *
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicyStatus
 */
export interface LoadBalancerPolicyStatus {
    /**
     * The state and workspace conditions of the applied resource.
     *
     * @generated from protobuf field: common.gloo.solo.io.Status common = 1;
     */
    common?: Status;
    /**
     * The number of destination ports selected by the policy.
     *
     * @generated from protobuf field: uint32 num_selected_destination_ports = 2;
     */
    numSelectedDestinationPorts: number;
}
/**
 * Report on the state of the LoadBalancerPolicyReport
 *
 * @generated from protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicyReport
 */
export interface LoadBalancerPolicyReport {
    /**
     * A list of workspaces in which the policy can apply to workloads.
     *
     * @generated from protobuf field: map<string, common.gloo.solo.io.Report> workspaces = 1;
     */
    workspaces: {
        [key: string]: Report;
    };
    /**
     * The list of destination ports selected by the policy.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationReference selected_destination_ports = 2;
     */
    selectedDestinationPorts: DestinationReference[];
}
// @generated message type with reflection information, may provide speed optimized methods
class LoadBalancerPolicySpec$Type extends MessageType<LoadBalancerPolicySpec> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec", [
            { no: 1, name: "apply_to_destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationSelector },
            { no: 2, name: "config", kind: "message", T: () => LoadBalancerPolicySpec_Config }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec
 */
export const LoadBalancerPolicySpec = new LoadBalancerPolicySpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoadBalancerPolicySpec_Config$Type extends MessageType<LoadBalancerPolicySpec_Config> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config", [
            { no: 1, name: "simple", kind: "enum", oneof: "lbPolicy", T: () => ["trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.SimpleLB", LoadBalancerPolicySpec_Config_SimpleLB] },
            { no: 2, name: "consistent_hash", kind: "message", oneof: "lbPolicy", T: () => LoadBalancerPolicySpec_Config_ConsistentHashLB },
            { no: 3, name: "warmup_duration_secs", kind: "message", T: () => Duration },
            { no: 4, name: "healthy_panic_threshold", kind: "message", T: () => DoubleValue },
            { no: 5, name: "update_merge_window", kind: "message", T: () => Duration }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config
 */
export const LoadBalancerPolicySpec_Config = new LoadBalancerPolicySpec_Config$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoadBalancerPolicySpec_Config_ConsistentHashLB$Type extends MessageType<LoadBalancerPolicySpec_Config_ConsistentHashLB> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.ConsistentHashLB", [
            { no: 1, name: "http_header_name", kind: "scalar", oneof: "hashKey", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "http_cookie", kind: "message", oneof: "hashKey", T: () => LoadBalancerPolicySpec_Config_ConsistentHashLB_HTTPCookie },
            { no: 3, name: "use_source_ip", kind: "scalar", oneof: "hashKey", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "http_query_parameter_name", kind: "scalar", oneof: "hashKey", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.ConsistentHashLB
 */
export const LoadBalancerPolicySpec_Config_ConsistentHashLB = new LoadBalancerPolicySpec_Config_ConsistentHashLB$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoadBalancerPolicySpec_Config_ConsistentHashLB_HTTPCookie$Type extends MessageType<LoadBalancerPolicySpec_Config_ConsistentHashLB_HTTPCookie> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.ConsistentHashLB.HTTPCookie", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ttl", kind: "message", T: () => Duration }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicySpec.Config.ConsistentHashLB.HTTPCookie
 */
export const LoadBalancerPolicySpec_Config_ConsistentHashLB_HTTPCookie = new LoadBalancerPolicySpec_Config_ConsistentHashLB_HTTPCookie$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoadBalancerPolicyStatus$Type extends MessageType<LoadBalancerPolicyStatus> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicyStatus", [
            { no: 1, name: "common", kind: "message", T: () => Status },
            { no: 2, name: "num_selected_destination_ports", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicyStatus
 */
export const LoadBalancerPolicyStatus = new LoadBalancerPolicyStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoadBalancerPolicyReport$Type extends MessageType<LoadBalancerPolicyReport> {
    constructor() {
        super("trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicyReport", [
            { no: 1, name: "workspaces", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Report } },
            { no: 2, name: "selected_destination_ports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message trafficcontrol.policy.gloo.solo.io.LoadBalancerPolicyReport
 */
export const LoadBalancerPolicyReport = new LoadBalancerPolicyReport$Type();
