import { HealthIndicator } from 'Components/Common/HealthIndicator';
import { Loading } from 'Components/Common/Loading';
import { SoloLink } from 'Components/Common/SoloLink';
import SoloTooltip from 'Components/Common/SoloTooltip';
import { Svg } from 'Components/Common/Svg';
import { colors } from 'Styles';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
import { Asset } from 'assets';
import { Status } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { DashboardHealthCardStyles } from './DashboardHealthCard.style';

type LinkInfo = {
  /** The label is only meaningful if there is >1 link. */
  label: string;
  link: string;
};
interface DashboardHealthCheckRowProps {
  status: Status | undefined | 'loading';
  label: string;
  logsLinks?: LinkInfo[];
  insightsLinks?: LinkInfo[];
  normalLinks?: {
    title: string;
    links: LinkInfo[];
  };
}
export const DashboardHealthCheckRow = ({
  status,
  label,
  logsLinks,
  insightsLinks,
  normalLinks
}: DashboardHealthCheckRowProps) => {
  if (status === 'loading') {
    return (
      <>
        <div>
          <Loading tiny />
        </div>
        <FlexLayout>{label}</FlexLayout>
        <div />
      </>
    );
  }

  return (
    <>
      <HealthIndicator status={status} borderColorOverride={!status ? colors.aprilGrey : undefined} />
      <DashboardHealthCardStyles.HealthCardRowStyle hasNoStatus={!status}>
        {label}
      </DashboardHealthCardStyles.HealthCardRowStyle>
      <FlexLayout gap={1} alignItems='center'>
        {insightsLinks?.length === 1 ? (
          <SoloTooltip title='View Insights'>
            <SoloLink link={insightsLinks[0].link} title={insightsLinks[0].label}>
              <Svg asset={<Asset.InsightsLinkIcon />} size={16} color={!status ? colors.juneGrey : colors.seaBlue} />
            </SoloLink>
          </SoloTooltip>
        ) : (
          !!insightsLinks?.length && (
            <SoloTooltip
              title={
                <>
                  View Insights for
                  {insightsLinks.map((linkInfo, idx) => (
                    <Spacer mt={1} key={idx}>
                      <SoloLink link={linkInfo.link} title={linkInfo.label} withArrow>
                        {linkInfo.label}
                      </SoloLink>
                    </Spacer>
                  ))}
                </>
              }>
              <Svg asset={<Asset.InsightsLinkIcon />} size={16} color={!status ? colors.juneGrey : colors.seaBlue} />
            </SoloTooltip>
          )
        )}
        {logsLinks?.length === 1 ? (
          <SoloTooltip title='View Logs'>
            <SoloLink link={logsLinks[0].link} title={logsLinks[0].label}>
              <Svg asset={<Asset.LogsLinkIcon />} size={16} color={!status ? colors.juneGrey : colors.seaBlue} />
            </SoloLink>
          </SoloTooltip>
        ) : (
          !!logsLinks?.length && (
            <SoloTooltip
              title={
                <>
                  View Logs for
                  {logsLinks.map((linkInfo, idx) => (
                    <Spacer mt={1} key={idx}>
                      <SoloLink link={linkInfo.link} title={linkInfo.label} withArrow>
                        {linkInfo.label}
                      </SoloLink>
                    </Spacer>
                  ))}
                </>
              }>
              <Svg asset={<Asset.LogsLinkIcon />} size={16} color={!status ? colors.juneGrey : colors.seaBlue} />
            </SoloTooltip>
          )
        )}
        {normalLinks?.links.length === 1 ? (
          <SoloTooltip title={normalLinks.title}>
            <SoloLink link={normalLinks.links[0].link} title={normalLinks.links[0].label}>
              <Svg asset={<Asset.ExternalLinkIcon />} size={16} color={!status ? colors.juneGrey : colors.seaBlue} />
            </SoloLink>
          </SoloTooltip>
        ) : (
          !!normalLinks?.links.length && (
            <SoloTooltip
              title={
                <>
                  {normalLinks.links.map((linkInfo, idx) => (
                    <Spacer mt={1} key={idx}>
                      <SoloLink link={linkInfo.link} title={linkInfo.label} withArrow>
                        {linkInfo.label}
                      </SoloLink>
                    </Spacer>
                  ))}
                </>
              }>
              <Svg asset={<Asset.LogsLinkIcon />} size={16} color={!status ? colors.juneGrey : colors.seaBlue} />
            </SoloTooltip>
          )
        )}
      </FlexLayout>
    </>
  );
};
