/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/security/access_policy.proto" (package "security.policy.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { ObjectReference } from "../../../common/v2/references_pb";
import { DestinationReference } from "../../../common/v2/references_pb";
import { Report } from "../../../common/v2/status_pb";
import { Status } from "../../../common/v2/status_pb";
import { IdentitySelector } from "../../../common/v2/selectors_pb";
import { EnforcementLayers } from "../../../common/v2/enforcement_layers_pb";
import { DestinationSelector } from "../../../common/v2/selectors_pb";
/**
 * Specifications for the policy.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicySpec
 */
export interface AccessPolicySpec {
    /**
     * Destinations to apply the policy to. If `applyToWorkloads` is non-empty,
     * this field is ignored. If this field and `applyToWorkloads` are both empty,
     * the policy applies to all ports on all destinations in the workspace.
     * {{< alert context="info" >}}
     * For security reasons, <code>applyToWorkloads</code> is preferred.
     * {{< /alert >}}
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationSelector apply_to_destinations = 1;
     */
    applyToDestinations: DestinationSelector[];
    /**
     * Workloads to apply the policy to. For security reasons,
     * this field is prefered over `applyToDestinations`. If an empty selector is
     * provided in the list, the policy applies to all workloads in a namespace, cluster,
     * and workspace that are available in the parent object's workspace.
     *
     * @generated from protobuf field: repeated security.policy.gloo.solo.io.AccessPolicySpec.NamespaceWorkloadSelector apply_to_workloads = 3;
     */
    applyToWorkloads: AccessPolicySpec_NamespaceWorkloadSelector[];
    /**
     * Details of the access policy to apply to the selected workloads.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.AccessPolicySpec.Config config = 2;
     */
    config?: AccessPolicySpec_Config;
}
/**
 * Select individual namespaces and workloads within the namespaces by label.
 * Workloads must have injected (sidecars) or be standalone proxies (gateways)
 * to be selected by Gloo policies.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicySpec.NamespaceWorkloadSelector
 */
export interface AccessPolicySpec_NamespaceWorkloadSelector {
    /**
     * Selector to match workload objects by their metadata.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.AccessPolicySpec.NamespaceWorkloadSelector.ObjectSelector selector = 1;
     */
    selector?: AccessPolicySpec_NamespaceWorkloadSelector_ObjectSelector;
}
/**
 * Selects zero or more Kubernetes workloads by matching on labels, namespace, cluster, and workspace.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicySpec.NamespaceWorkloadSelector.ObjectSelector
 */
export interface AccessPolicySpec_NamespaceWorkloadSelector_ObjectSelector {
    /**
     * Only select workloads with matching labels.
     *
     * @generated from protobuf field: map<string, string> labels = 1;
     */
    labels: {
        [key: string]: string;
    };
    /**
     * Only select objects in the matching namespace. If empty, Gloo selects
     * matching objects across all namespaces available in the parent object's workspace.
     *
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
    /**
     * Only select objects in the matching cluster. If empty, Gloo selects
     * matching objects across all clusters available in the parent object's workspace.
     *
     * @generated from protobuf field: string cluster = 3;
     */
    cluster: string;
    /**
     * Only select objects in the given workspace. If empty, Gloo selects
     * matching objects across all workspaces available in the parent object's workspace.
     *
     * @generated from protobuf field: string workspace = 4;
     */
    workspace: string;
}
/**
 * Details of the access policy to apply to the selected workloads.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config
 */
export interface AccessPolicySpec_Config {
    /**
     * How clients are authenticated to the workload.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.AccessPolicySpec.Config.Authentication authn = 1;
     */
    authn?: AccessPolicySpec_Config_Authentication;
    /**
     * Deprecated; Use authzList instead. If authzList is set, it takes precedence and this field is ignored.
     * How clients are authorized to access the workload.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization authz = 2;
     */
    authz?: AccessPolicySpec_Config_Authorization;
    /**
     * Optional: When NetworkPolicy translation is enabled, all available layers are used to enforce AccessPolicies by default.
     * If you want to explicitly define which layers to use to enforce this AccessPolicy, you can set them by using this field.
     * Note that the layer that you define in this field must be available to be configured.
     *
     * @generated from protobuf field: common.gloo.solo.io.EnforcementLayers enforcement_layers = 3;
     */
    enforcementLayers?: EnforcementLayers;
    /**
     * How clients are authorized to access the workload.
     * A request is allowed when it matches at least one authz entry in the list (logically OR'd together).
     *
     * @generated from protobuf field: repeated security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization authz_list = 4;
     */
    authzList: AccessPolicySpec_Config_Authorization[];
}
/**
 * How clients are authenticated to the workload.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config.Authentication
 */
export interface AccessPolicySpec_Config_Authentication {
    /**
     * Type of TLS policy that is enforced on clients connecting to the workload.
     * If service isolation is enabled for the workspace, this field is treated as 'STRICT'.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.AccessPolicySpec.Config.Authentication.TLSmode tls_mode = 1;
     */
    tlsMode: AccessPolicySpec_Config_Authentication_TLSmode;
}
/**
 * The mutual TLS (mTLS) connection mode. The following enums correspond to the
 * [modes defined by Istio](https://github.com/istio/api/blob/master/security/v1beta1/peer_authentication.proto#L129).
 *
 * @generated from protobuf enum security.policy.gloo.solo.io.AccessPolicySpec.Config.Authentication.TLSmode
 */
export enum AccessPolicySpec_Config_Authentication_TLSmode {
    /**
     * Do not originate mTLS connections to the upstream workload,
     * and instead use unencrypted plaintext.
     *
     * @generated from protobuf enum value: DISABLE = 0;
     */
    DISABLE = 0,
    /**
     * Permit both unencrypted plaintext and mTLS-secured connections to the upstream workload.
     * Use this mode only when you migrate workloads to your service mesh.
     * After the workload is onboarded to the service mesh, using the `STRICT` mode is recommended.
     *
     * @generated from protobuf enum value: PERMISSIVE = 1;
     */
    PERMISSIVE = 1,
    /**
     * Secure connections to the upstream workload with mTLS by presenting
     * client certificates for authentication.
     * This mode uses certificates generated
     * automatically by Istio for mTLS authentication. When you use
     * this mode, keep all other fields in `ClientTLSSettings` empty.
     *
     * @generated from protobuf enum value: STRICT = 2;
     */
    STRICT = 2
}
/**
 * Configure access to workloads.
 *
 * You can have multiple `authzList` entries.
 * A request is allowed when it matches at least one of the `authzList` entries (logically OR'd together).
 *
 * For each entry, you can specify different requirements for allowed clients, paths, methods, IP blocks, and other configuration settings.
 * Then, a request is allowed only when ALL of the requirements are met (logically AND'd together).
 *
 * If the policy uses `applyToWorkloads`, you can also allow NO requests by setting this value to the empty object `{}`,
 * which will serve as a fallback when requests do not match another `authz` case for the given workload.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization
 */
export interface AccessPolicySpec_Config_Authorization {
    /**
     * Client identities that are permitted to access the workload.
     * To allow access for all client identities, provide a single empty selector.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.IdentitySelector allowed_clients = 1;
     */
    allowedClients: IdentitySelector[];
    /**
     *
     * Optional: A list of HTTP paths or gRPC methods to allow.
     * gRPC methods must be presented as fully-qualified name in the form of
     * "/packageName.serviceName/methodName", and are case sensitive.
     * Exact match, prefix match, and suffix match are supported for paths.
     * For example, the path `/books/review` matches
     * `/books/review` (exact match), `*books/` (suffix match), or `/books*` (prefix match).
     *
     * If empty, any path is allowed.
     *
     * @generated from protobuf field: repeated string allowed_paths = 2;
     */
    allowedPaths: string[];
    /**
     *
     * Optional: A list of HTTP methods to allow (e.g., "GET", "POST").
     * If empty, any method is allowed.
     * This field is ignored for gRPC, because the value is always "POST".
     *
     * @generated from protobuf field: repeated string allowed_methods = 3;
     */
    allowedMethods: string[];
    /**
     *
     * Optional: Additional request matching conditions.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec match = 4;
     */
    match?: AccessPolicySpec_Config_Authorization_MatchSpec;
    /**
     * Optional: A list of IP blocks, populated from the source address of the IP packet.
     * Single IP addresses (e.g. “1.2.3.4”) and CIDRs (e.g. “1.2.3.0/24”) are supported. If empty,
     * any IP address is allowed.
     *
     * @generated from protobuf field: repeated string allowed_ip_blocks = 5;
     */
    allowedIpBlocks: string[];
    /**
     * Optional: A list of IP blocks, populated from X-Forwarded-For header or proxy protocol.
     * Single IP addresses (e.g. “1.2.3.4”) and CIDRs (e.g. “1.2.3.0/24”) are supported. This field
     * is equivalent to the remote.ip attribute. If empty, any IP address is allowed.
     * {{< alert >}}
     * To use this field, you must configure the <code>meshConfig.defaultConfig.gatewayTopology.numTrustedProxies</code>
     * field in your Istio installation. For more info, see the
     * <a href="https://istio.io/latest/docs/ops/configuration/traffic-management/network-topologies/#configuring-network-topologies">Istio documentation</a>.
     * {{< /alert >}}
     *
     * @generated from protobuf field: repeated string allowed_remote_ip_blocks = 6;
     */
    allowedRemoteIpBlocks: string[];
    /**
     * Set to true to enable a dry run of the access policy for L7 Istio service mesh authorization only. Then, you can check the sidecar proxy logs, metrics, and tracing to determine if traffic would be allowed or denied. However, the authorization is not enforced until you disable the dry run and re-apply the access policy.
     * Note that when there are both dry run and enforced policies, dry run policies are considered independently of enforced policies;
     * i.e. the logs, metrics, and tracing results indicating if traffic would be allowed or denied is based on the behavior if all dry run policies were enforced but all currently enforced policies were deleted.
     * Note that dry run cannot be used to review allow or deny decisions for L4 traffic. Even if you enable the dry run feature with a Gloo Network setup, no Cilium network policy and decision logs are created or enforced.
     *
     * @generated from protobuf field: bool dry_run = 7;
     */
    dryRun: boolean;
}
/**
 * Optional: Additional request matching conditions.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec
 */
export interface AccessPolicySpec_Config_Authorization_MatchSpec {
    /**
     * Optional: HTTP request header matching conditions.
     *
     * @generated from protobuf field: security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec.RequestSpec request = 1;
     */
    request?: AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec;
}
/**
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec.RequestSpec
 */
export interface AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec {
    /**
     *
     * Optional: HTTP request header matching conditions.
     *
     * @generated from protobuf field: map<string, security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec.RequestSpec.HeaderValues> headers = 1;
     */
    headers: {
        [key: string]: AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec_HeaderValues;
    };
}
/**
 * A value matching condition for HTTP request headers.
 * At least one of `values` or `notValues` must be set.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec.RequestSpec.HeaderValues
 */
export interface AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec_HeaderValues {
    /**
     * A list of match values for the header. A request must match at least one value.
     * Supports wildcards. For example, to
     * match a request with header values containing `exact-books` OR `partial-matched-books`,
     * set `values` to `exact-books` and `partial-*-books`.
     *
     * @generated from protobuf field: repeated string values = 1;
     */
    values: string[];
    /**
     * A list of negative match values for the header. A request must not match any values.
     * Supports wildcards. For example, to
     * _not_ match a request with header values containing `ignore-books` or `partial-ignored-books`,
     * set `notValues` to `ignore-books` and `partial-ig*-books`.
     *
     * @generated from protobuf field: repeated string not_values = 2;
     */
    notValues: string[];
}
/**
 * The status of the policy after it is applied to your Gloo environment.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicyStatus
 */
export interface AccessPolicyStatus {
    /**
     * The state and workspace conditions of the applied policy.
     *
     * @generated from protobuf field: common.gloo.solo.io.Status common = 1;
     */
    common?: Status;
    /**
     * The number of destination ports selected by the policy.
     *
     * @generated from protobuf field: uint32 num_selected_destination_ports = 5;
     */
    numSelectedDestinationPorts: number;
    /**
     * The number of namespaces containing selected workloads by the policy.
     *
     * @generated from protobuf field: uint32 num_selected_namespaces = 2;
     */
    numSelectedNamespaces: number;
    /**
     * The number of service accounts allowed to access the selected destinations.
     *
     * @generated from protobuf field: uint32 num_allowed_service_accounts = 3;
     */
    numAllowedServiceAccounts: number;
}
/**
 * The report shows the resources that the policy selects after the policy is successfully applied.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicyReport
 */
export interface AccessPolicyReport {
    /**
     * A list of workspaces in which the policy can apply to destinations.
     *
     * @generated from protobuf field: map<string, common.gloo.solo.io.Report> workspaces = 1;
     */
    workspaces: {
        [key: string]: Report;
    };
    /**
     * A list of destination ports selected by the policy.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationReference selected_destination_ports = 2;
     */
    selectedDestinationPorts: DestinationReference[];
    /**
     * A list of the service accounts whose workloads are allowed
     * to send requests to the selected destinations.
     *
     * @generated from protobuf field: repeated security.policy.gloo.solo.io.AccessPolicyReport.IdentityReference allowed_service_accounts = 3;
     */
    allowedServiceAccounts: AccessPolicyReport_IdentityReference[];
    /**
     * A list of namespaces that contain workloads selected by the policy.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.ObjectReference selected_namespaces = 4;
     */
    selectedNamespaces: ObjectReference[];
}
/**
 * A list of the service accounts whose workloads are allowed
 * to send requests to the selected destinations.
 *
 * @generated from protobuf message security.policy.gloo.solo.io.AccessPolicyReport.IdentityReference
 */
export interface AccessPolicyReport_IdentityReference {
    /**
     * The index of the identity in the list of identity selectors.
     *
     * @generated from protobuf field: int32 identity_index = 1;
     */
    identityIndex: number;
    /**
     * The reference to the service account backing the identity.
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectReference service_account = 2;
     */
    serviceAccount?: ObjectReference;
    /**
     * The index of the authz in the authzList.
     *
     * @generated from protobuf field: int32 authz_index = 3;
     */
    authzIndex: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicySpec$Type extends MessageType<AccessPolicySpec> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicySpec", [
            { no: 1, name: "apply_to_destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationSelector },
            { no: 3, name: "apply_to_workloads", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AccessPolicySpec_NamespaceWorkloadSelector },
            { no: 2, name: "config", kind: "message", T: () => AccessPolicySpec_Config }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicySpec
 */
export const AccessPolicySpec = new AccessPolicySpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicySpec_NamespaceWorkloadSelector$Type extends MessageType<AccessPolicySpec_NamespaceWorkloadSelector> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicySpec.NamespaceWorkloadSelector", [
            { no: 1, name: "selector", kind: "message", T: () => AccessPolicySpec_NamespaceWorkloadSelector_ObjectSelector }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicySpec.NamespaceWorkloadSelector
 */
export const AccessPolicySpec_NamespaceWorkloadSelector = new AccessPolicySpec_NamespaceWorkloadSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicySpec_NamespaceWorkloadSelector_ObjectSelector$Type extends MessageType<AccessPolicySpec_NamespaceWorkloadSelector_ObjectSelector> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicySpec.NamespaceWorkloadSelector.ObjectSelector", [
            { no: 1, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "workspace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicySpec.NamespaceWorkloadSelector.ObjectSelector
 */
export const AccessPolicySpec_NamespaceWorkloadSelector_ObjectSelector = new AccessPolicySpec_NamespaceWorkloadSelector_ObjectSelector$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicySpec_Config$Type extends MessageType<AccessPolicySpec_Config> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicySpec.Config", [
            { no: 1, name: "authn", kind: "message", T: () => AccessPolicySpec_Config_Authentication },
            { no: 2, name: "authz", kind: "message", T: () => AccessPolicySpec_Config_Authorization },
            { no: 3, name: "enforcement_layers", kind: "message", T: () => EnforcementLayers },
            { no: 4, name: "authz_list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AccessPolicySpec_Config_Authorization }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config
 */
export const AccessPolicySpec_Config = new AccessPolicySpec_Config$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicySpec_Config_Authentication$Type extends MessageType<AccessPolicySpec_Config_Authentication> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicySpec.Config.Authentication", [
            { no: 1, name: "tls_mode", kind: "enum", T: () => ["security.policy.gloo.solo.io.AccessPolicySpec.Config.Authentication.TLSmode", AccessPolicySpec_Config_Authentication_TLSmode] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config.Authentication
 */
export const AccessPolicySpec_Config_Authentication = new AccessPolicySpec_Config_Authentication$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicySpec_Config_Authorization$Type extends MessageType<AccessPolicySpec_Config_Authorization> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization", [
            { no: 1, name: "allowed_clients", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IdentitySelector },
            { no: 2, name: "allowed_paths", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "allowed_methods", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "match", kind: "message", T: () => AccessPolicySpec_Config_Authorization_MatchSpec },
            { no: 5, name: "allowed_ip_blocks", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "allowed_remote_ip_blocks", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "dry_run", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization
 */
export const AccessPolicySpec_Config_Authorization = new AccessPolicySpec_Config_Authorization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicySpec_Config_Authorization_MatchSpec$Type extends MessageType<AccessPolicySpec_Config_Authorization_MatchSpec> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec", [
            { no: 1, name: "request", kind: "message", T: () => AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec
 */
export const AccessPolicySpec_Config_Authorization_MatchSpec = new AccessPolicySpec_Config_Authorization_MatchSpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec$Type extends MessageType<AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec.RequestSpec", [
            { no: 1, name: "headers", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec_HeaderValues } }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec.RequestSpec
 */
export const AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec = new AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec_HeaderValues$Type extends MessageType<AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec_HeaderValues> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec.RequestSpec.HeaderValues", [
            { no: 1, name: "values", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "not_values", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicySpec.Config.Authorization.MatchSpec.RequestSpec.HeaderValues
 */
export const AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec_HeaderValues = new AccessPolicySpec_Config_Authorization_MatchSpec_RequestSpec_HeaderValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicyStatus$Type extends MessageType<AccessPolicyStatus> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicyStatus", [
            { no: 1, name: "common", kind: "message", T: () => Status },
            { no: 5, name: "num_selected_destination_ports", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "num_selected_namespaces", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "num_allowed_service_accounts", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicyStatus
 */
export const AccessPolicyStatus = new AccessPolicyStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicyReport$Type extends MessageType<AccessPolicyReport> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicyReport", [
            { no: 1, name: "workspaces", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Report } },
            { no: 2, name: "selected_destination_ports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationReference },
            { no: 3, name: "allowed_service_accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AccessPolicyReport_IdentityReference },
            { no: 4, name: "selected_namespaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ObjectReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicyReport
 */
export const AccessPolicyReport = new AccessPolicyReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessPolicyReport_IdentityReference$Type extends MessageType<AccessPolicyReport_IdentityReference> {
    constructor() {
        super("security.policy.gloo.solo.io.AccessPolicyReport.IdentityReference", [
            { no: 1, name: "identity_index", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "service_account", kind: "message", T: () => ObjectReference },
            { no: 3, name: "authz_index", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message security.policy.gloo.solo.io.AccessPolicyReport.IdentityReference
 */
export const AccessPolicyReport_IdentityReference = new AccessPolicyReport_IdentityReference$Type();
