import { SoloModal } from 'Components/Common/SoloModal';
import { ObjectRef } from 'proto/github.com/solo-io/skv2/api/core/v1/core_pb';
import { useState } from 'react';
// import { WorkloadDetails } from '../../../../Features/Meshes/DetailsTables/WorkloadDetails';
import { graphApi } from 'Api/graphs';
import {
  EnforcedByInfoListBlock,
  getMetricsListLineData,
  getNodeIcon,
  httpMetricsListToCounts,
  isTimestampsListOverHourLong
} from './DetailsTab';
import { TimelineGraph } from './TimelineGraph';
// import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { Asset } from 'assets';
import { Loading } from 'Components/Common/Loading';
import {
  EdgeMetrics,
  HttpMetrics
} from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/graph_pb';
import { di } from 'react-magnetic-di';
import { TruncateText } from 'Styles/CommonEmotions/text';
import { useGraphFetchSettingsContext } from '../../Context/GraphFetchSettingsContext';
import {
  findWorstHealth,
  getEdgeMetricsType,
  getEdgeStatus,
  isCiliumMetricsDefined,
  isHttpMetricsDefined,
  isTcpMetricsDefined
} from '../get-graph-items-data';
import { GraphMetricsType } from '../graph-selection-utils';
import { CiliumSection } from './CiliumSection';
import { DetailsTabStyles } from './DetailsTab.style';
import { EdgeDetailsStyles } from './EdgeDetails.style';
import { LatencySection } from './LatencySection';
import { prettierNumbersBySpace } from './NodeDetails';
import { TcpSection } from './TcpSection';

const { useGetEdgeMetrics } = graphApi;

function parseHttpTimelineGraphData(metricsIn: HttpMetrics[], metricsOut: HttpMetrics[]) {
  const multipleHours =
    metricsIn.some(metric => isTimestampsListOverHourLong(metric.timestamps)) ||
    metricsOut.some(metric => isTimestampsListOverHourLong(metric.timestamps));

  const lineDataIn = getMetricsListLineData(metricsIn, [{ id: 'in', key: 'requestCount' }], multipleHours);
  const lineDataOut = getMetricsListLineData(metricsOut, [{ id: 'out', key: 'requestCount' }], multipleHours);

  const dataset = [...lineDataIn.dataset, ...lineDataOut.dataset];
  let graphDataExists = !!dataset.length && Math.max(lineDataIn.maxY, lineDataOut.maxY) > 0;
  return { dataset, graphDataExists };
}

export type EdgeDetailsProps = {
  details: EdgeMetrics;
  splitView?: boolean;
};
export const EdgeDetails = ({ details, splitView }: EdgeDetailsProps) => {
  di(useGetEdgeMetrics);
  const [workloadBeingExamined, setWorkloadBeingExamined] = useState<ObjectRef>();
  // const domNavigate = useNavigate();

  const { fetchSettings } = useGraphFetchSettingsContext();

  const { data: edgeMetrics } = useGetEdgeMetrics(
    details?.sourceWorkload?.id,
    details?.targetWorkload?.id,
    fetchSettings.endTime,
    fetchSettings.window,
    fetchSettings.step,
    fetchSettings.istioMetrics,
    fetchSettings.ciliumMetrics,
    fetchSettings.tcpMetrics
  );

  const metricsType = getEdgeMetricsType(details);

  // If split view, then only look at outgoing
  const incomingEdgeMetrics = splitView ? [] : edgeMetrics?.incomingEdgeMetrics,
    outgoingEdgeMetrics = edgeMetrics?.outgoingEdgeMetrics;

  const incomingMetrics = incomingEdgeMetrics?.map(e => e.httpMetrics).filter(isHttpMetricsDefined) ?? [];
  const outgoingMetrics = outgoingEdgeMetrics?.map(e => e.httpMetrics).filter(isHttpMetricsDefined) ?? [];
  const httpMetricsExist = incomingMetrics.length + outgoingMetrics.length > 0;

  const ciliumMetricsIn = incomingEdgeMetrics?.map(n => n.ciliumMetrics).filter(isCiliumMetricsDefined) ?? [];
  const ciliumMetricsOut = outgoingEdgeMetrics?.map(n => n.ciliumMetrics).filter(isCiliumMetricsDefined) ?? [];
  const ciliumMetricsExist = ciliumMetricsIn.length + ciliumMetricsOut.length > 0;

  const inCounts = httpMetricsListToCounts(incomingMetrics);
  const outCounts = httpMetricsListToCounts(outgoingMetrics);

  const startingTime = Math.min(inCounts.startingTime, outCounts.startingTime);
  const endingTime = Math.max(inCounts.endingTime, outCounts.endingTime);
  const totalTime = endingTime - startingTime || 1;

  // Default to showing status with incoming edges unless there are none, in which case fall back to outgoing
  let healthEdges = outgoingEdgeMetrics ?? [];
  if (healthEdges.length === 0) {
    healthEdges = incomingEdgeMetrics ?? [];
  }
  const status = findWorstHealth(healthEdges.map(getEdgeStatus));

  const isTwoWayEdge =
    metricsType === GraphMetricsType.Tcp ||
    ((incomingEdgeMetrics?.length ?? 0) > 0 && (outgoingEdgeMetrics?.length ?? 0) > 0);

  const { dataset, graphDataExists } = parseHttpTimelineGraphData(incomingMetrics, outgoingMetrics);

  const openSourceModal = () => {
    /*setWorkloadBeingExamined(sourceNode?.summary?.workloadRef);*/
  };
  const openTargetModal = () => {
    /*setWorkloadBeingExamined(targetNode?.summary?.workloadRef);*/
  };

  if (!edgeMetrics) {
    return <Loading message='Loading edge...' />;
  }

  const security = incomingMetrics[0]?.security ?? outgoingMetrics[0]?.security ?? details.tcpMetrics?.security;

  const sourceNodeName = details?.sourceWorkload?.displayName ?? '';
  const targetNodeName = details?.targetWorkload?.displayName ?? '';

  return (
    <>
      <EdgeDetailsStyles.TitleRow data-testid={`${sourceNodeName}-title-row`}>
        <EdgeDetailsStyles.EdgeArrowHolder status={status} metricsType={metricsType}>
          {isTwoWayEdge ? (
            <Asset.EdgeBiDirection data-testid='edge-twoway-direction' />
          ) : (
            <Asset.EdgeSingleDirection data-testid='edge-single-direction' />
          )}
        </EdgeDetailsStyles.EdgeArrowHolder>

        <EdgeDetailsStyles.NodeTitle data-testid='source-node-title' onClick={openSourceModal}>
          <EdgeDetailsStyles.NodeTitleIconHolder>
            {getNodeIcon(details?.sourceWorkload?.nodeType)}
          </EdgeDetailsStyles.NodeTitleIconHolder>
          <TruncateText title={sourceNodeName} mouseLeaveDelay={0}>
            {sourceNodeName}
            {/* <ModalPopIconStyled /> */}
          </TruncateText>
        </EdgeDetailsStyles.NodeTitle>
        <EdgeDetailsStyles.NodeTitleStatusHolder data-testid='edge-status'>
          {/*sourceWorkloadMetrics && (
            <>
              {sourceNodeStatus.toLowerCase()}
              <GraphStatusIndicator status={sourceNodeStatus} />
            </>
          )*/}
        </EdgeDetailsStyles.NodeTitleStatusHolder>

        <EdgeDetailsStyles.NodeTitle onClick={openTargetModal} data-testid='target-node-title'>
          <EdgeDetailsStyles.NodeTitleIconHolder>
            {getNodeIcon(details?.targetWorkload?.nodeType)}
          </EdgeDetailsStyles.NodeTitleIconHolder>
          <TruncateText title={targetNodeName} mouseLeaveDelay={0}>
            {targetNodeName}
            {/* <ModalPopIconStyled /> */}
          </TruncateText>
        </EdgeDetailsStyles.NodeTitle>
        <EdgeDetailsStyles.NodeTitleStatusHolder>
          {/*targetWorkloadMetrics && (
            <>
              {targetNodeStatus.toLowerCase()}
              <GraphStatusIndicator status={targetNodeStatus} />
            </>
          )*/}
        </EdgeDetailsStyles.NodeTitleStatusHolder>
      </EdgeDetailsStyles.TitleRow>

      {
        /* // We are removing the unlock icons and unsecure notes for now as it is
            //  causing confusion for users in several edge cases.
            // If we can figure out better business-logic (likely in backend)
            //  then this code can be re-enabled.*/
        !!edgeMetrics && httpMetricsExist && !!security && (
          <DetailsTabStyles.InfoListBlock data-testid='security-policy'>
            {
              <>
                <div>
                  <EdgeDetailsStyles.MtlsIcon className='icon-secure' secure={true} />
                </div>
                <div>
                  mTLS: {security.securityPolicy}
                  <Tooltip
                    title={
                      <EdgeDetailsStyles.MtlsTooltipList>
                        <li>
                          <span>mTLS: {security.securityPolicy}</span>
                        </li>
                        {security.sourcePrincipal && (
                          <li>
                            <span>{security.sourcePrincipal}</span>
                          </li>
                        )}
                        {security.destinationPrincipal && (
                          <li>
                            <span>{security.destinationPrincipal}</span>
                          </li>
                        )}
                      </EdgeDetailsStyles.MtlsTooltipList>
                    }
                    trigger='hover'>
                    <EdgeDetailsStyles.InfoIcon />
                  </Tooltip>
                </div>
              </>
            }
          </DetailsTabStyles.InfoListBlock>
        )
      }

      <EnforcedByInfoListBlock metricsType={metricsType} />

      {httpMetricsExist && (
        <>
          <DetailsTabStyles.SectionTitle>Traffic</DetailsTabStyles.SectionTitle>
          <DetailsTabStyles.SubsectionTitle>HTTP (requests per second)</DetailsTabStyles.SubsectionTitle>
          <DetailsTabStyles.GridTable columns={['18%', '24%', '1fr', '27%']} data-testid='edge-details-traffic-table'>
            {/*Top row */}
            <DetailsTabStyles.GridTH></DetailsTabStyles.GridTH>
            <DetailsTabStyles.GridTH>RPS</DetailsTabStyles.GridTH>
            <DetailsTabStyles.GridTH>% Success</DetailsTabStyles.GridTH>
            <DetailsTabStyles.GridTH>% Error</DetailsTabStyles.GridTH>
            {/* INs */}
            <DetailsTabStyles.GridLabel>In</DetailsTabStyles.GridLabel>
            <div title={inCounts.requestCount.toString()}>{prettierNumbersBySpace(inCounts.requestCount)}</div>
            <div>
              {!!inCounts.successCount
                ? prettierNumbersBySpace(Math.round((inCounts.successCount / inCounts.requestCount) * 100))
                : '--'}
            </div>
            <div>
              {!!inCounts.failureCount
                ? prettierNumbersBySpace(Math.round((inCounts.failureCount / inCounts.requestCount) * 100))
                : '--'}
            </div>
            {/* OUTs */}
            <DetailsTabStyles.GridLabel>Out</DetailsTabStyles.GridLabel>
            <div title={outCounts.requestCount.toString()}>{prettierNumbersBySpace(outCounts.requestCount)}</div>
            <div>
              {!!outCounts.successCount
                ? prettierNumbersBySpace(Math.round((outCounts.successCount / outCounts.requestCount) * 100))
                : '--'}
            </div>
            <div>
              {!!outCounts.failureCount
                ? prettierNumbersBySpace(Math.round((outCounts.failureCount / outCounts.requestCount) * 100))
                : '--'}
            </div>
          </DetailsTabStyles.GridTable>

          {graphDataExists && (
            <>
              <DetailsTabStyles.SectionHorizontalRule />
              <DetailsTabStyles.SubsectionTitle data-testid='edge-details-traffic-graph'>
                HTTP - Request Traffic ({(totalTime / 60).toFixed(0)}min)
              </DetailsTabStyles.SubsectionTitle>
              <DetailsTabStyles.SubsectionDetails>
                RPS: {prettierNumbersBySpace(outCounts.rps + inCounts.rps)}, % Error:{' '}
                {(
                  (outCounts.failureCount + inCounts.failureCount) /
                  (outCounts.requestCount + inCounts.requestCount)
                ).toFixed(2)}
              </DetailsTabStyles.SubsectionDetails>

              <TimelineGraph dataset={dataset} fixedHeight={'175px'} legendData={[{ name: 'Request' }]} />
            </>
          )}

          {!!details?.httpMetrics?.requestLatencies?.p99.length && (
            <LatencySection requestLatencies={details.httpMetrics.requestLatencies} />
          )}
        </>
      )}

      {ciliumMetricsExist && (
        <>
          <DetailsTabStyles.SectionHorizontalRule />
          <CiliumSection metricsIn={ciliumMetricsIn} metricsOut={ciliumMetricsOut} isEdgeDetails />
        </>
      )}

      {isTcpMetricsDefined(details.tcpMetrics) && (
        <>
          <DetailsTabStyles.SectionHorizontalRule />
          <TcpSection metric={details.tcpMetrics} />
        </>
      )}

      {!!workloadBeingExamined && (
        <SoloModal visible={true} width={1000} onClose={() => setWorkloadBeingExamined(undefined)}>
          {'TODO' /* <WorkloadDetails workloadRef={workloadBeingExamined} /> */}
        </SoloModal>
      )}
    </>
  );
};
