import { di } from 'react-magnetic-di';
import useSWR from 'swr';

// List has to be here to avoid circular dependency issues
export const graphValidTimeIntervalList = [
  { value: 60, displayValue: 'Last 1 min' },
  { value: 300, displayValue: 'Last 5 mins' },
  { value: 900, displayValue: 'Last 15 mins' },
  { value: 1800, displayValue: 'Last 30 mins' },
  { value: 3600, displayValue: 'Last 1 hr' }
];

// List has to be here to avoid circular dependency issues
export const graphValidRefreshList = [
  { value: 5000, displayValue: 'Every 5s' },
  { value: 15000, displayValue: 'Every 15s' },
  { value: 60000, displayValue: 'Every 1m' },
  { value: 300000, displayValue: 'Every 5m' },
  { value: 900000, displayValue: 'Every 15m' }
];

export const GRAPH_CACHE_KEY = 'graph-cache';
export interface GraphCacheProp {
  manuallyMovedWorkspaceOrientedNodePositions: Record<string, { x: number; y: number }>;
  manuallyMovedClusterOrientedNodePositions: Record<string, { x: number; y: number }>;
  timeInterval: number;
  refreshRate: number;
  animationsOn: boolean;
  infraOn: boolean;
  workspacesOn: boolean;
  mTLSOn: boolean;
  tcpOn: boolean;
  ciliumOn: boolean;
  idlesOn: boolean;
  kubernetesServicesDisplayed: boolean;
  externalServicesDisplayed: boolean;
  gatewaysDisplayed: boolean;
  workspaceFilters: string[];
  namespaceFilters: string[];
  clusterFilters: string[];
}

export function getDefaultGraphCacheObject(): GraphCacheProp {
  return {
    manuallyMovedWorkspaceOrientedNodePositions: {},
    manuallyMovedClusterOrientedNodePositions: {},
    timeInterval: 900,
    refreshRate: 60000,
    animationsOn: true,
    infraOn: true,
    workspacesOn: false,
    mTLSOn: true,
    tcpOn: true,
    ciliumOn: true,
    idlesOn: false,
    kubernetesServicesDisplayed: true,
    externalServicesDisplayed: true,
    gatewaysDisplayed: true,
    workspaceFilters: [],
    namespaceFilters: [],
    clusterFilters: []
  };
}

export function getGraphCache() {
  // Note: due to how `useGetGraphCache` works, any non-primitive values in here should not be used as
  // effect arguements, since they are all stored/fetched as 1 data
  let cache: GraphCacheProp = getDefaultGraphCacheObject();

  try {
    const data = localStorage.getItem(GRAPH_CACHE_KEY);

    if (data) {
      const pdata: Partial<GraphCacheProp> = JSON.parse(data);
      // prevent invalid traffic fetch options
      if (pdata.timeInterval && !graphValidTimeIntervalList.find(o => o.value === pdata.timeInterval)) {
        delete pdata.timeInterval;
      }
      if (pdata.refreshRate && !graphValidRefreshList.find(o => o.value === pdata.refreshRate)) {
        delete pdata.refreshRate;
      }

      // combine with default values encase a new field has been added to cache since last save
      cache = { ...cache, ...pdata };
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return cache;
}

export const setGraphCache = (value: Partial<GraphCacheProp>) => {
  const data = { ...getGraphCache(), ...value };

  try {
    localStorage.setItem(GRAPH_CACHE_KEY, JSON.stringify(data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

// update via mutate
export function useGetGraphCache() {
  di(getGraphCache);
  // We use useSWR as a redux store, and only ever trigger it via mutate
  const { data, mutate } = useSWR<Partial<GraphCacheProp>>(GRAPH_CACHE_KEY, () => {
    return getGraphCache();
  });
  return {
    // Since swr will return data as undefined for a cycle, return direct cache data to avoid needless
    // "data undefined" errors or tons of needless `?.`s (which also would require a custom default)
    cache: (data ?? getGraphCache()) as GraphCacheProp,
    updateCache: (options: Partial<GraphCacheProp>) => {
      // Update the cache then call mutate with updated cache values
      setGraphCache(options);
      // use `getGraphCache()` instead of manually combining data above encase cache was updated through `setGraphCache()` directly.
      // That may be useful in cases where you don't want to trigger a re-render, such as saving node positions
      mutate(getGraphCache());
    }
  };
}
