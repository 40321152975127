/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_number,add_pb_suffix,generate_dependencies,optimize_code_size,ts_nocheck,eslint_disable
// @generated from protobuf file "github.com/solo-io/gloo-mesh-enterprise/v2/api/gloo.solo.io/policy/v2/resilience/failover_policy.proto" (package "resilience.policy.gloo.solo.io", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { ObjectReference } from "../../../common/v2/references_pb";
import { DestinationReference } from "../../../common/v2/references_pb";
import { Report } from "../../../common/v2/status_pb";
import { Status } from "../../../common/v2/status_pb";
import { UInt32Value } from "../../../../../../../../../google/protobuf/wrappers_pb";
import { DestinationSelector } from "../../../common/v2/selectors_pb";
/**
 * Specifications for the policy.
 *
 * @generated from protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec
 */
export interface FailoverPolicySpec {
    /**
     * Select the virtual destinations to apply the policy to by using labels.
     * If empty, the policy applies to all vitrual destinations in the workspace.
     * {{< alert context="info" >}}
     * Failover policies can currently select Gloo virtual destinations only.
     * Selecting Kubernetes services or Gloo external services is not supported.
     * {{< /alert >}}
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationSelector apply_to_destinations = 1;
     */
    applyToDestinations: DestinationSelector[];
    /**
     * The details of the failover policy to apply to the selected virtual destinations.
     *
     * @generated from protobuf field: resilience.policy.gloo.solo.io.FailoverPolicySpec.Config config = 2;
     */
    config?: FailoverPolicySpec_Config;
}
/**
 * @generated from protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec.Config
 */
export interface FailoverPolicySpec_Config {
    /**
     * Map the localities to fail over traffic from one region, zone,
     * or subzone to another in case of failure. The locality is determined
     * by the Kubernetes labels on the node where the destination's app runs.
     * For more information, see the [Istio docs](https://istio.io/latest/docs/tasks/traffic-management/locality-load-balancing/).
     *
     * You can exclude this setting to enable the locality load balancer
     * settings without mappings.
     *
     * Only priority labels or locality mappings can be specified, not both.
     *
     * @generated from protobuf field: repeated resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings locality_mappings = 2;
     */
    localityMappings: FailoverPolicySpec_Config_LocalityMappings[];
    /**
     * @generated from protobuf oneof: priority_levels
     */
    priorityLevels: {
        oneofKind: "priorityLabels";
        /**
         * Prioritize destinations to fail over traffic to by configuring priority labels. In general, destinations that match the most labels have higher priority during failover.
         * For more information, see the [Istio docs](https://istio.io/latest/docs/reference/config/networking/destination-rule/#:~:text=No-,failoverPriority,-string%5B%5D).
         *
         * When using priority labels, you must specify either an ordered list of label keys or an ordered list of label key-value pairs.
         * For example, you can specify `["region"]` or `["region=us-east-1", "availabilityzone=1a", "datacenter=4"]`.
         * You cannot have an ordered list that includes both label keys and label key-value pairs.
         *
         * Only priority labels or locality mappings can be specified, not both. If you specify both, Gloo Mesh will give precedence to the locality mappings.
         * You can use locality labels in your list of priority labels, such as the Kubernetes `topology.kubernetes.io/region` node label.
         *
         * @generated from protobuf field: resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.PriorityLabels priority_labels = 3;
         */
        priorityLabels: FailoverPolicySpec_Config_PriorityLabels;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.PriorityLabels
 */
export interface FailoverPolicySpec_Config_PriorityLabels {
    /**
     * @generated from protobuf field: repeated string labels = 1;
     */
    labels: string[];
}
/**
 * @generated from protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings
 */
export interface FailoverPolicySpec_Config_LocalityMappings {
    /**
     * The locality of the destination where Gloo Mesh originally
     * tried to fulfill the request.
     *
     * @generated from protobuf field: resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings.OriginatingLocality from = 1;
     */
    from?: FailoverPolicySpec_Config_LocalityMappings_OriginatingLocality;
    /**
     * The localities of the destination where Gloo Mesh can reroute requests.
     * You must specify the region, and optionally the zone and subzone.
     * If you have multiple `to` destinations, you can optionally set a weight.
     *
     * @generated from protobuf field: repeated resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings.DestinationLocality to = 2;
     */
    to: FailoverPolicySpec_Config_LocalityMappings_DestinationLocality[];
}
/**
 * A geographic location defined by a region, zone, and sub-zone.
 *
 * @generated from protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings.OriginatingLocality
 */
export interface FailoverPolicySpec_Config_LocalityMappings_OriginatingLocality {
    /**
     * The locality's region.
     *
     * @generated from protobuf field: string region = 1;
     */
    region: string;
    /**
     * (Optional) The locality's zone.
     *
     * @generated from protobuf field: string zone = 2;
     */
    zone: string;
    /**
     * (Optional) The locality's sub-zone.
     *
     * @generated from protobuf field: string sub_zone = 3;
     */
    subZone: string;
}
/**
 * A geographic location defined by a region, zone, and sub-zone
 * along with the weight for the destination.
 *
 * @generated from protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings.DestinationLocality
 */
export interface FailoverPolicySpec_Config_LocalityMappings_DestinationLocality {
    /**
     * The locality's region.
     *
     * @generated from protobuf field: string region = 1;
     */
    region: string;
    /**
     * (Optional) The locality's zone.
     *
     * @generated from protobuf field: string zone = 2;
     */
    zone: string;
    /**
     * (Optional) The locality's sub-zone.
     *
     * @generated from protobuf field: string sub_zone = 3;
     */
    subZone: string;
    /**
     * (Optional) Weight for destination locality. All weights must add up to 100.
     * If not provided, weight is determined based on other destination locality weights.
     * For information about the value format, see the
     * [Google protocol buffer documentation](https://protobuf.dev/reference/protobuf/google.protobuf/#u-int32-value).
     *
     * @generated from protobuf field: google.protobuf.UInt32Value weight = 4;
     */
    weight?: UInt32Value;
}
/**
 * The status of the policy after it is applied to your Gloo environment.
 *
 * @generated from protobuf message resilience.policy.gloo.solo.io.FailoverPolicyStatus
 */
export interface FailoverPolicyStatus {
    /**
     * The state and workspace conditions of the applied resource.
     *
     * @generated from protobuf field: common.gloo.solo.io.Status common = 1;
     */
    common?: Status;
    /**
     * The number of destination ports selected by the policy.
     *
     * @generated from protobuf field: uint32 num_selected_destination_ports = 2;
     */
    numSelectedDestinationPorts: number;
}
/**
 * The report shows the resources that the policy selects after the policy is successfully applied.
 *
 * @generated from protobuf message resilience.policy.gloo.solo.io.FailoverPolicyReport
 */
export interface FailoverPolicyReport {
    /**
     * A list of workloads selected by the policy.
     *
     * @generated from protobuf field: map<string, common.gloo.solo.io.Report> workspaces = 1;
     */
    workspaces: {
        [key: string]: Report;
    };
    /**
     * A list of destination ports selected by the policy.
     *
     * @generated from protobuf field: repeated common.gloo.solo.io.DestinationReference selected_destination_ports = 2;
     */
    selectedDestinationPorts: DestinationReference[];
    /**
     * A list of selected outlier detection policies.
     *
     * @generated from protobuf field: repeated resilience.policy.gloo.solo.io.DestinationToSelectedOutlierDetection corresponding_outlier_detection_policies = 3;
     */
    correspondingOutlierDetectionPolicies: DestinationToSelectedOutlierDetection[];
}
/**
 * @generated from protobuf message resilience.policy.gloo.solo.io.DestinationToSelectedOutlierDetection
 */
export interface DestinationToSelectedOutlierDetection {
    /**
     * The destination to which the selected outlier detection policy is applied.
     *
     * @generated from protobuf field: common.gloo.solo.io.DestinationReference destination = 1;
     */
    destination?: DestinationReference;
    /**
     * The selected outlier detection policy.
     *
     * @generated from protobuf field: common.gloo.solo.io.ObjectReference selected_outlier_detection = 2;
     */
    selectedOutlierDetection?: ObjectReference;
}
// @generated message type with reflection information, may provide speed optimized methods
class FailoverPolicySpec$Type extends MessageType<FailoverPolicySpec> {
    constructor() {
        super("resilience.policy.gloo.solo.io.FailoverPolicySpec", [
            { no: 1, name: "apply_to_destinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationSelector },
            { no: 2, name: "config", kind: "message", T: () => FailoverPolicySpec_Config }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec
 */
export const FailoverPolicySpec = new FailoverPolicySpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FailoverPolicySpec_Config$Type extends MessageType<FailoverPolicySpec_Config> {
    constructor() {
        super("resilience.policy.gloo.solo.io.FailoverPolicySpec.Config", [
            { no: 2, name: "locality_mappings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FailoverPolicySpec_Config_LocalityMappings },
            { no: 3, name: "priority_labels", kind: "message", oneof: "priorityLevels", T: () => FailoverPolicySpec_Config_PriorityLabels }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec.Config
 */
export const FailoverPolicySpec_Config = new FailoverPolicySpec_Config$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FailoverPolicySpec_Config_PriorityLabels$Type extends MessageType<FailoverPolicySpec_Config_PriorityLabels> {
    constructor() {
        super("resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.PriorityLabels", [
            { no: 1, name: "labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.PriorityLabels
 */
export const FailoverPolicySpec_Config_PriorityLabels = new FailoverPolicySpec_Config_PriorityLabels$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FailoverPolicySpec_Config_LocalityMappings$Type extends MessageType<FailoverPolicySpec_Config_LocalityMappings> {
    constructor() {
        super("resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings", [
            { no: 1, name: "from", kind: "message", T: () => FailoverPolicySpec_Config_LocalityMappings_OriginatingLocality },
            { no: 2, name: "to", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FailoverPolicySpec_Config_LocalityMappings_DestinationLocality }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings
 */
export const FailoverPolicySpec_Config_LocalityMappings = new FailoverPolicySpec_Config_LocalityMappings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FailoverPolicySpec_Config_LocalityMappings_OriginatingLocality$Type extends MessageType<FailoverPolicySpec_Config_LocalityMappings_OriginatingLocality> {
    constructor() {
        super("resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings.OriginatingLocality", [
            { no: 1, name: "region", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "sub_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings.OriginatingLocality
 */
export const FailoverPolicySpec_Config_LocalityMappings_OriginatingLocality = new FailoverPolicySpec_Config_LocalityMappings_OriginatingLocality$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FailoverPolicySpec_Config_LocalityMappings_DestinationLocality$Type extends MessageType<FailoverPolicySpec_Config_LocalityMappings_DestinationLocality> {
    constructor() {
        super("resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings.DestinationLocality", [
            { no: 1, name: "region", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "sub_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "weight", kind: "message", T: () => UInt32Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.FailoverPolicySpec.Config.LocalityMappings.DestinationLocality
 */
export const FailoverPolicySpec_Config_LocalityMappings_DestinationLocality = new FailoverPolicySpec_Config_LocalityMappings_DestinationLocality$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FailoverPolicyStatus$Type extends MessageType<FailoverPolicyStatus> {
    constructor() {
        super("resilience.policy.gloo.solo.io.FailoverPolicyStatus", [
            { no: 1, name: "common", kind: "message", T: () => Status },
            { no: 2, name: "num_selected_destination_ports", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.FailoverPolicyStatus
 */
export const FailoverPolicyStatus = new FailoverPolicyStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FailoverPolicyReport$Type extends MessageType<FailoverPolicyReport> {
    constructor() {
        super("resilience.policy.gloo.solo.io.FailoverPolicyReport", [
            { no: 1, name: "workspaces", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Report } },
            { no: 2, name: "selected_destination_ports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationReference },
            { no: 3, name: "corresponding_outlier_detection_policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DestinationToSelectedOutlierDetection }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.FailoverPolicyReport
 */
export const FailoverPolicyReport = new FailoverPolicyReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DestinationToSelectedOutlierDetection$Type extends MessageType<DestinationToSelectedOutlierDetection> {
    constructor() {
        super("resilience.policy.gloo.solo.io.DestinationToSelectedOutlierDetection", [
            { no: 1, name: "destination", kind: "message", T: () => DestinationReference },
            { no: 2, name: "selected_outlier_detection", kind: "message", T: () => ObjectReference }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message resilience.policy.gloo.solo.io.DestinationToSelectedOutlierDetection
 */
export const DestinationToSelectedOutlierDetection = new DestinationToSelectedOutlierDetection$Type();
