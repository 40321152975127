const version = 'latest'; // latest = last release version, main = currently worked on version

////////////////////
// Enterprise
////////////////////
const entPath = `https://docs.solo.io/gloo-mesh-enterprise/${version}`;
// prettier-ignore
const enterpriseBaseUrls = {
  root: `${entPath}/`,
  workspace_setup: `${entPath}/setup/prod/workspaces/workspace_setup/`,
  meshctl_cluster: `${entPath}/reference/cli/meshctl_cluster/`,
  enterprise_installation: `${entPath}/setup/install/enterprise_installation/`,
  recommended_setup: `${entPath}/setup/prod/recommended_setup/`,
  gs_multi: `${entPath}/getting_started/multi/gs_multi/`,

  multi_tenancy: `${entPath}/setup/prod/workspaces/about/`,
  workspace_export: `${entPath}/setup/prod/workspaces/about/workspace-export/`,
  workspace_configuration: `${entPath}/setup/prod/workspaces/about/workspace-configuration/`,

  policies_overview: `${entPath}/concepts/about/policies/overview/`,
  supported_policies: `${entPath}/concepts/about/policies/supported-policies/`,
  trim_proxy_config_policy: `${entPath}/resiliency/trim-proxy-config/`,
  access_policy: `${entPath}/security/access/`,
  wasm_policy: `${entPath}/traffic_management/wasm/wasm-policy/`,
  transformation_policy_api: `${entPath}/reference/api/transformation_policy/`,

  api_reference: `${entPath}/reference/api/`,
  routing: `${entPath}/traffic_management/`,
  tracing: `${entPath}/observability/customize/tracing/`
} as const;

const enterprise = {
  ...enterpriseBaseUrls,
  workspace_configuration_HASH: {
    service_isolation: `${enterpriseBaseUrls.workspace_configuration}#service-isolation`
  },
  workspace_setup_HASH: {
    configure_workspace_settings: `${enterpriseBaseUrls.workspace_setup}#workspace-settings`
  },
  enterprise_installation_HASH: {
    helm_register: `${enterpriseBaseUrls.enterprise_installation}#helm-register`
  },
  gs_multi_HASH: {
    before_you_begin: `${enterpriseBaseUrls.gs_multi}#before-you-begin`,
    gloo_server: `${enterpriseBaseUrls.gs_multi}#gloo-server`,
    gloo_agents: `${enterpriseBaseUrls.gs_multi}#gloo-agents`
  },
  wasm_policy_HASH: {
    apply_filter: `${enterpriseBaseUrls.wasm_policy}#apply-filter`
  },
  transformation_policy_api_HASH: {
    requesttransformation: `${enterpriseBaseUrls.transformation_policy_api}#transformationpolicyspec-config-requesttransformation`
  },
  recommended_setup_HASH: {
    redis_safemode: `${enterpriseBaseUrls.recommended_setup}#redis-safemode`
  },
  policies_overview_HASH: {
    policy_resources: `${enterpriseBaseUrls.policies_overview}#policy-resources`
  }
} as const;

////////////////////
// Core
////////////////////
const corePath = `https://docs.solo.io/gloo-mesh-core/${version}`;
// prettier-ignore
const coreBaseUrls = {
  root:               `${corePath}/`,
  troubleshooting_ui: `${corePath}/troubleshooting/ui/`,
  insights:           `${corePath}/insights/`,
  quickstart:         `${corePath}/quickstart`,
  quickstart_multi:   `${corePath}/quickstart/multi/`,
  istio_mesh_ilm:     `${corePath}/istio/mesh/ilm/`,
  tracing:            `${corePath}/observability/customize/tracing/`
} as const;

const core = {
  ...coreBaseUrls,
  insights_HASH: {
    review_fips_compliance: `${coreBaseUrls.insights}#review-fips-compliance`
  },
  quickstart_multi_HASH: {
    data_plane: `${coreBaseUrls.quickstart_multi}#data-plane`
  }
} as const;

////////////////////
// Gateway
////////////////////
const gatePath = `https://docs.solo.io/gloo-mesh-gateway/${version}`;
// prettier-ignore
const gatewayBaseUrls = {
  root:       `${gatePath}/`,
  graphql:    `${gatePath}/graphql/`,
  graphql_ui: `${gatePath}/graphql/graphql_ui/`
} as const;

const gateway = {
  ...gatewayBaseUrls,
  graphql_ui_HASH: {
    try_out_graphql_apis: `${gatewayBaseUrls.graphql_ui}#try-out-graphql-apis`
  }
} as const;

////////////////////
// Doc Links Export
////////////////////
export const docLinks = { enterprise, core, gateway };
export const testDocLinks = { ...enterpriseBaseUrls, ...coreBaseUrls, ...gatewayBaseUrls };

////////////////////
// Non-doc external links
////////////////////
export const externalLinks = {
  solo_io: {
    root: `https://www.solo.io`,
    privacy_policy: `https://legal.solo.io/#privacy-policy`,
    talk_to_an_expert: `https://www.solo.io/company/talk-to-an-expert/`
  },
  graphql_org: {
    learn_queries: `https://graphql.org/learn/queries/`
  },
  cilium_io: {
    ebpf_maps: `https://docs.cilium.io/en/stable/network/ebpf/maps/`
  },
  istio_io: {
    news_xml: `https://istio.io/latest/news/feed.xml`
  }
};
