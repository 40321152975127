import React, { ComponentProps } from 'react';
import { Tooltip } from 'antd';
import { Svg } from './Svg';
import { Asset } from 'assets';

type Props = { tooltip: React.ReactNode; size?: number } & Omit<ComponentProps<typeof Tooltip>, 'title'>;
export const InfoTooltip = ({ tooltip, size = 14, ...tooltipProps }: Props) => {
  return (
    <Tooltip title={tooltip} {...tooltipProps}>
      <Svg asset={<Asset.InfoIcon />} color={thm => thm.augustGrey} size={size} inline />
    </Tooltip>
  );
};
