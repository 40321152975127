import { debugApi } from 'Api/debug';
import { Asset } from 'assets';
import { SoloCheckboxListDropdown } from 'Components/Common/Input/SoloCheckboxList';
import { SoloInput } from 'Components/Common/Input/SoloInput';
import SoloLabelInput from 'Components/Common/Input/SoloLabelInput';
import { Loading } from 'Components/Common/Loading';
import HealthCountBox, {
  defaultHealthyStatusFilter,
  defaultUnHealthyStatusFilter,
  statusFiltersAreEqual,
  toggleStatusFilter
} from 'Components/Common/Overview/HealthCountBox';
import { OverviewStyles } from 'Components/Common/Overview/Overview.style';
import SoloGridTableToggle, { useIsTableViewPreferred } from 'Components/Common/Overview/SoloGridTableToggle';
import { OverviewTablePageSize, useSoloPaging } from 'Components/Common/SoloPagination';
import { TagList } from 'Components/Common/SoloTag';
import { State } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/common_pb';
import { ResourceType } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/resources/resources_pb';
import { useEffect, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useLocation, useParams } from 'react-router-dom';
import { getEnumValues, sortObjectRefs } from 'utils/helpers';
import { useDebouncedRefreshIndicator } from 'utils/hooks';
import { Permission, usePermissionGateRedirect } from 'utils/permissions';
import { getGvkTypeFromId, GvkType, gvkTypeMap, resourceTypesMap } from 'utils/types';
import { parseResourceSearchParams } from 'utils/url-builders';
import { urlStrings } from 'utils/url-string-constants';
import { ResourcesLandingBody } from './ResourcesLandingBody';
const { useGetResourcesList } = debugApi;

export const ResourcesLanding = () => {
  di(useGetResourcesList, useParams);
  const { category } = useParams();
  const { isTable, setIsTable } = useIsTableViewPreferred();
  const categoryType = useMemo(() => getGvkTypeFromId(category), [category]);

  usePermissionGateRedirect(
    categoryType === GvkType.ISTIO
      ? Permission.IstioEnabled
      : categoryType === GvkType.CILIUM
      ? Permission.CiliumEnabled
      : categoryType === GvkType.GLOO
      ? Permission.SoloResources
      : categoryType === GvkType.KUBE_NON_GATEWAY
      ? Permission.Kubernetes
      : categoryType === GvkType.KUBE_GATEWAY_API
      ? Permission.GatewayApi
      : undefined,
    `/${urlStrings.resources}/`
  );

  const location = useLocation();
  const [initialClusterValue, initialTypeValues] = useMemo(() => {
    const params = parseResourceSearchParams(location.search);
    return [params.cluster ?? '', params.types ?? []];
  }, [location.search]);

  const [searchTextFilter, setSearchTextFilter] = useState(initialClusterValue || '');
  const [allowedResourceTypes, setAllowedResourceTypes] = useState<ResourceType[]>([]);
  const [resourceTypeFilter, setResourceTypeFilter] = useState<ResourceType[]>(initialTypeValues);
  const [labelKeyFilter, setLabelKeyFilter] = useState('');
  const [labelValueFilter, setLabelValueFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<State[]>([]);
  const { pagingData, apiPaginationObject, useSetPagingTotal } = useSoloPaging(OverviewTablePageSize);

  // The status filters work as a typical filter since we don't select their values directly
  // ("unhealthy" selects several statuses), so it's not included here.
  const hasFilters = searchTextFilter !== '' || resourceTypeFilter.length > 0; // || !!statusFilter.length

  useEffect(() => {
    setAllowedResourceTypes(
      getEnumValues(ResourceType)
        .filter(
          rt =>
            resourceTypesMap[rt].gvkType === categoryType &&
            rt !== ResourceType.UNKNOWN_RESOURCE_TYPE &&
            resourceTypesMap[rt].gvkType !== GvkType.GLOO_INTERNAL
        )
        .sort((rt1, rt2) => resourceTypesMap[rt1].name.localeCompare(resourceTypesMap[rt2].name))
    );
    setResourceTypeFilter(list => list.filter(rt => resourceTypesMap[rt].gvkType === categoryType));
  }, [categoryType]);

  const { data: listResourcesResponse, error: outputResourcesError } = useGetResourcesList(
    searchTextFilter,
    resourceTypeFilter.length === 0 ? allowedResourceTypes : resourceTypeFilter,
    undefined,
    labelKeyFilter,
    labelValueFilter,
    statusFilter,
    apiPaginationObject
  );
  const isLoading = listResourcesResponse === undefined;
  useSetPagingTotal(listResourcesResponse?.filteredTotal);
  const { showRefreshIndicator } = useDebouncedRefreshIndicator(listResourcesResponse);

  const removeResourceType = (typeToRemove: ResourceType) => {
    setResourceTypeFilter(resourceTypeFilter.filter(rt => rt !== typeToRemove));
  };

  const resourceList = listResourcesResponse?.resources.sort((rA, rB) => sortObjectRefs(rA.ref, rB.ref)) ?? [];
  const showResourceTypeFilter = categoryType !== GvkType.CILIUM && allowedResourceTypes.length > 0;

  return (
    <OverviewStyles.Container data-testid='resources-landing'>
      <OverviewStyles.Header.Header>
        <OverviewStyles.Header.Title>
          {showRefreshIndicator ? <Loading small /> : <Asset.ResourcesIcon />}
          Resources
        </OverviewStyles.Header.Title>

        {!outputResourcesError && (
          <OverviewStyles.Header.FiltersWrapper>
            <HealthCountBox
              onHealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultHealthyStatusFilter)}
              onUnhealthyClick={() => toggleStatusFilter(statusFilter, setStatusFilter, defaultUnHealthyStatusFilter)}
              isHealthySelected={statusFiltersAreEqual(statusFilter, defaultHealthyStatusFilter)}
              isUnhealthySelected={statusFiltersAreEqual(statusFilter, defaultUnHealthyStatusFilter)}
              totalCount={listResourcesResponse?.totalResources}
              errorCount={listResourcesResponse?.errors}
            />
            <OverviewStyles.Header.Filters filters={2 + Number(showResourceTypeFilter)}>
              <SoloLabelInput
                labelKey={labelKeyFilter}
                onLabelKeyChange={setLabelKeyFilter}
                labelValue={labelValueFilter}
                onLabelValueChange={setLabelValueFilter}
              />
              <SoloInput
                data-testid='search-by-filter'
                value={searchTextFilter}
                placeholder={'Search by...'}
                aria-label={'Search by'}
                onChange={evt => setSearchTextFilter(evt.target.value)}
              />
              {showResourceTypeFilter && (
                <SoloCheckboxListDropdown
                  data-testid='filter-by-filter'
                  selectAll
                  noSearch
                  title={'Filter by...'}
                  values={resourceTypeFilter}
                  options={allowedResourceTypes.map(type => ({
                    key: type,
                    value: type,
                    label: resourceTypesMap[type].name
                  }))}
                  onChange={newVals => {
                    setResourceTypeFilter(newVals);
                  }}
                />
              )}
              <SoloGridTableToggle isTable={isTable} onChange={newIsList => setIsTable(newIsList)} />
            </OverviewStyles.Header.Filters>
          </OverviewStyles.Header.FiltersWrapper>
        )}
      </OverviewStyles.Header.Header>
      {hasFilters && (
        <OverviewStyles.TagsListHolder>
          <TagList
            includePadding
            tags={[
              {
                value: 'Clear all',
                color: thm => thm.butternutOrange,
                onClose: () => {
                  setSearchTextFilter('');
                  setResourceTypeFilter([]);
                }
              },

              // We could show status filters here, but:
              // - it would show "State:APPROVED" if healthy is selected, and
              //   "State:FAILED", "State:INVALID", "State:PENDING", and
              //   "State:WARNING" if unhealthy is selected.
              // - the other pages that use <HealthCountBox> would need
              //   to be updated for consistency.
              // ...statusFilter.map(s => ({
              //   label: 'State',
              //   value: getEnumKeys(State)[s],
              //   onClose: () => setStatusFilter(statusFilter.filter(sf => sf !== s))
              // })),

              ...resourceTypeFilter.map(resourceType => ({
                label: 'Type',
                value: resourceTypesMap[resourceType].name,
                onClose: () => {
                  removeResourceType(resourceType);
                }
              })),

              !!searchTextFilter && {
                label: 'Search',
                value: searchTextFilter,
                onClose: () => setSearchTextFilter('')
              }
            ]}
          />
        </OverviewStyles.TagsListHolder>
      )}
      <ResourcesLandingBody
        filtersOn={hasFilters}
        isLoading={isLoading}
        itemsError={outputResourcesError}
        isTable={isTable}
        items={resourceList ?? []}
        pagingData={pagingData}
        data-testid='resources-landing-body-empty'
        icon={<Asset.FaultInjectionIcon />}
        docsLink={false}
        resourceNamePlural='Resources'
        overrideInitialSuggestionString={`You don’t have any ${
          gvkTypeMap[categoryType!]?.name ?? 'unknown'
        } resources.`}
        overrideTitleString='No Resources Registered'
      />
    </OverviewStyles.Container>
  );
};
