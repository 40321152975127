import { BaseLandingBodyProps, CustomLandingBodyProps, LandingBody } from 'Components/Common/Overview/LandingBody';
import { OverviewGridStyles } from 'Components/Common/Overview/OverviewGrid.style';
import { SecondaryInfo, SecondaryInfoItem } from 'Components/Common/SecondaryInfo';
import { SoloPagination } from 'Components/Common/SoloPagination';
import { SoloColumnsType, SoloTable } from 'Components/Common/SoloTable';
import SoloTableTextEllipsesCell from 'Components/Common/SoloTableTextEllipsesCell';
import { StringList } from 'Components/Common/StringList';
// import { Svg } from 'Components/Common/Svg';
// import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Spacer } from 'Styles/CommonEmotions/spacer';
// import { Asset } from 'assets';
import { Svg } from 'Components/Common/Svg';
import { FlexLayout } from 'Styles/CommonEmotions/flexLayout';
import { Asset } from 'assets';
import { ListEgressSourcesResponse_EgressSourceSummary } from 'proto/github.com/solo-io/gloo-mesh-enterprise/v2/gloo-mesh-ui/api/rpc.gloo/v2/overview_pb';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ExpandableStringList from '../../../Common/ExpandableStringList';

const MtlsSecure = () => (
  <>
    <Svg height={15} asset={<Asset.MtlsSecure />} mr={1} color={theme => theme.oceanBlue} />
    Secure
  </>
);
const MtlsInsecure = () => (
  <>
    <Svg height={15} asset={<Asset.MtlsInsecure />} mr={1} color={theme => theme.grapefruitOrange} />
    Insecure
  </>
);

const getEgressSourceDetailsUrl = (source: ListEgressSourcesResponse_EgressSourceSummary) => {
  return '#';
};

export const TrafficEgressLandingBody = (
  props: CustomLandingBodyProps<ListEgressSourcesResponse_EgressSourceSummary>
) => {
  return (
    <LandingBody
      {...props}
      GridComponent={TrafficEgressLandingBodyGrid}
      TableComponent={TrafficEgressLandingBodyTable}
    />
  );
};

//
// Grid variant
//
export const TrafficEgressLandingBodyGrid = ({
  items,
  pagingData
}: BaseLandingBodyProps<ListEgressSourcesResponse_EgressSourceSummary>) => {
  return (
    <>
      <OverviewGridStyles.Grid data-testid='traffic-egress-landing-body'>
        {items.map(item => {
          let metaInfo: { label: string; data: NonNullable<ReactNode> }[] = [];
          metaInfo.push({ label: 'Host Name', data: item.hostName });
          if (item.tlsState !== 'unknown' && item.tlsState !== '') {
            metaInfo.push({
              label: 'Certificate',
              data:
                item.tlsState === 'secure' || item.tlsState === 'mutual_tls' ? (
                  <FlexLayout alignItems='center'>
                    <MtlsSecure />
                  </FlexLayout>
                ) : (
                  <FlexLayout>
                    <MtlsInsecure />
                  </FlexLayout>
                )
            });
          }
          const itemKey = `${item.hostName}:${item.port}`;
          return (
            <li key={itemKey}>
              <a
                href={getEgressSourceDetailsUrl(item)}
                data-testid={`${itemKey}-egress-source-card`}
                aria-label={`Egress Source: ${itemKey}`}>
                <OverviewGridStyles.Card title={undefined}>
                  <OverviewGridStyles.CardHeader>{item.hostName}</OverviewGridStyles.CardHeader>
                  <OverviewGridStyles.CardContent>
                    <SecondaryInfo items={metaInfo} small={true} />
                  </OverviewGridStyles.CardContent>
                  <OverviewGridStyles.CardFooter>
                    <SecondaryInfoItem
                      small
                      label='Consuming Services'
                      data={<StringList list={item.consumingServices} />}
                    />
                  </OverviewGridStyles.CardFooter>
                </OverviewGridStyles.Card>
              </a>
            </li>
          );
        })}
      </OverviewGridStyles.Grid>
      {!!pagingData.total && <SoloPagination {...pagingData} pageSizeOptions={[3, 6, 12, 24]} />}
    </>
  );
};

//
// Table variant
//
interface TableFields {
  key: string;
  hostName: string;
  port: string;
  tlsState: string;
  services: string[];
  url: string;
}
const columns: SoloColumnsType<TableFields> = [
  {
    title: 'Host Name',
    dataIndex: 'hostName',
    // width: '20%',
    ellipsis: true,
    render: value => <SoloTableTextEllipsesCell value={value} />
  },

  //{ title: 'Port', dataIndex: 'port' },
  {
    title: 'TLS',
    dataIndex: 'tlsState',
    render: tlsState =>
      tlsState === 'secure' || tlsState === 'mutual_tls' ? (
        <FlexLayout alignItems='center'>
          <MtlsSecure />
        </FlexLayout>
      ) : tlsState === 'insecure' || tlsState === 'none' ? (
        <FlexLayout>
          <MtlsInsecure />
        </FlexLayout>
      ) : null
  },
  {
    title: 'Consuming Services',
    dataIndex: 'services',
    render: services => <ExpandableStringList items={services} />
  }
];

const TrafficEgressLandingBodyTable = ({
  items,
  pagingData
}: BaseLandingBodyProps<ListEgressSourcesResponse_EgressSourceSummary>) => {
  const navigate = useNavigate();

  const tableData = useMemo<TableFields[]>(
    () =>
      items.map(item => {
        return {
          key: `${item.hostName}:${item.port}`,
          hostName: item.hostName,
          port: item.port,
          tlsState: item.tlsState,
          services: item.consumingServices,
          url: getEgressSourceDetailsUrl(item)
        };
      }),
    [items]
  );

  //
  // Render
  //
  return (
    <Spacer mt={5}>
      <SoloTable
        data-testid='traffic-egress-landing-body'
        dataSource={tableData}
        columns={columns}
        paging={pagingData}
        onRow={({ url }) => ({
          onClick: !!url ? () => navigate(url) : undefined
        })}
      />
    </Spacer>
  );
};
